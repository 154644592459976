<template>
    <!-- <div class="welcome-modal-container" id="modal-opened"> -->
    <div class="welcome-modal" @click.stop>

        <div class="welcome-modal__details">
            <h1 class="welcome-modal__title">
                <app-i18n code="welcomeModal.title" />
            </h1>
            <!-- <p class="welcome-modal__description">Sentence that will tell user what this welcome-modal is for or
                something.</p> -->
        </div>

        <p class="welcome-modal__text">
            <app-i18n code="welcomeModal.text" />
        </p>

        <button class="welcome-modal__btn" dir="ltr" @click="goToEvent">
            <app-i18n code="welcomeModal.btn" /> &rarr;
        </button>

        <a href="javascript:;" class="link-2" @click="$parent.$emit('close-modal')"></a>

    </div>
    <!-- </div> -->
</template>

<script>
export default {
    methods: {
        goToEvent() {
            this.$router.push('/october-event')
            this.$parent.$emit('close-modal')
        }
    }
}
</script>

<style lang="scss">
.welcome-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;

    display: none;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background: hsla(0, 0%, 40%, .6);
}

/* using :target */
.welcome-modal-container:target {
    display: flex;
}

.welcome-modal {
    width: 56vw;
    padding: 4rem 2rem;
    border-radius: .8rem;
    text-align: center;
    // color: hsl(0, 0%, 100%);
    color: #294bac;
    box-shadow: .4rem .4rem 2.4rem .2rem hsla(236, 50%, 50%, 0.3);
    position: relative;
    //  background: linear-gradient(to right bottom, hsl(25deg 50% 50%), hsl(25deg 86% 60%));
    background: #fff;
    overflow: hidden;

    @media (max-width: 500px) {
        width: 80vw;
    }
}

.welcome-modal__details {
    text-align: center;
    margin-bottom: 4rem;
    padding-bottom: 4rem;
    // border-bottom: 1px solid hsla(0, 0%, 100%, .4);
    border-bottom: 1px solid #294bac;

    @media (max-width: 960px) {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }
}

.welcome-modal__title {
    font-size: 2.6rem;

    @media (max-width: 960px) {
        font-size: 2.2rem;
    }

    @media (max-width: 840px) {
        margin-top: 1.5rem;
    }

    @media (max-width: 620px) {
        margin-top: 0rem;
        font-size: 1.6rem;
    }
}

.welcome-modal__description {
    margin-top: 2rem;

    font-size: 1.6rem;
    font-style: italic;
}

.welcome-modal__text {
    padding: 0 4rem;
    margin-bottom: 4rem;
    font-size: 1.5rem;
    line-height: 2;

    @media (max-width: 960px) {
        font-size: 1.4rem;
    }

    @media (max-width: 840px) {
        padding: 0 1rem;
    }

    @media (max-width: 620px) {
        padding: 0;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }
}

.welcome-modal__text::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 18rem;
    height: 18rem;
    // border: 1px solid hsla(0, 0%, 100%, .2);
    border: 1px solid #294bac;
    border-radius: 100rem;
    pointer-events: none;
}

.welcome-modal__btn {
    padding: 1rem 1.6rem;
    // border: 1px solid hsla(0, 0%, 100%, .4);
    border: 1px solid #294bac;
    border-radius: 100rem;
    color: inherit;
    background: transparent;
    font-size: 1.4rem;
    // font-family: inherit;
    // letter-spacing: .2rem;

    transition: .2s;
    cursor: pointer;

    @media (max-width: 620px) {
        font-size: 1.2rem;
    }

    @media (max-width: 500px) {
        padding: 0.8rem 0.8rem;
    }

}

.welcome-modal__btn:hover,
.welcome-modal__btn:focus {
    // border-color: hsla(0, 0%, 100%, .6);
    border-color: #294bac;
    transform: translateY(-.2rem);
}

.link-2 {
    width: 4rem;
    height: 4rem;
    // border: 1px solid hsla(0, 0%, 100%, .4);
    border: 1px solid #294bac;
    border-radius: 100rem;

    color: inherit;
    font-size: 2.2rem;

    position: absolute;
    top: 2rem;
    right: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;

    @media (max-width: 620px) {
        width: 1.7rem;
        height: 1.7rem;
        font-size: 1.6rem;
    }

}

.link-2::before {
    content: '×';

    transform: translateY(-.1rem);
}

.link-2:hover,
.link-2:focus {
    // border-color: hsla(0, 0%, 100%, .6);
    border-color: #294bac;
    transform: translateY(-.2rem);
}
</style>