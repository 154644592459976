<template>
  <router-view />
  <ErrorNotify message="--text--"></ErrorNotify>
  <SuccessNotify message="--text--"></SuccessNotify>
  <JoinedNotify message="--text--"></JoinedNotify>
  <transition name="fade" mode="out-in">
    <base-modal v-show="welcomeModalShow && !firstTime" @close-modal="closeWelcomeModal" :indep="true">
      <template #indep-modal>
        <welcome-modal></welcome-modal>
      </template>
    </base-modal>
  </transition>
</template>

<script>
import firebaseInit from "@/firebase/firebase-init";
import baseModal from '@/components/modals/base-modal.vue'
import welcomeModal from '@/components/welcome-modal/welcome-modal.vue'

export default {
  components: {
    baseModal,
    welcomeModal
  },
  computed: {
    language() {
      return this.$i18n.locale;
    },
    isRTL() {
      return this.$i18n.locale == "ar";
    },
  },
  data: () => ({
    welcomeModalShow: false,
  }),
  mounted() {
    const promises = [new Promise(() => firebaseInit())];
    Promise.all(promises).then(console.log('init'))

    if (location.pathname == '/') {
      this.welcomeModalShow = true
    }
  },
  watch: {
    isRTL() {
      document.title = this.$t("app.title");
    },
  },
  methods: {
    closeWelcomeModal() {
      this.welcomeModalShow = false
    },
  },
  created() {
    document.title = this.$t("app.title");
    const language = this.language || localStorage.getItem("language");
    localStorage.setItem("language", language);
    if (language == "ar") {
      document.body.setAttribute("dir", "rtl");
      document.body.setAttribute("lang", "ar");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap");

body::-webkit-scrollbar {
  width: 8px !important;
  // color: $Pelorous !important;
  // background: $Pelorous;
  border-radius: 50px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

body::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #ff8c40 !important;
  // outline: 5px solid greenyellow !important;
}

body,
body[dir="ltr"] {
  font-family: "Nunito";
  color: #707876;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  overflow-x: hidden;
}

body,
body[dir="rtl"] {
  font-family: "Cairo";
  color: #707876;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}

.vue-tel-input {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid rgba(gray, 0.2) !important;
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
