<template>
    <event-ticker></event-ticker>
    <main-header></main-header>
    <main-slider></main-slider>
    <!-- <form-tabs></form-tabs> -->
    <section-about></section-about>
    <count-numbers></count-numbers>
    <what-we-do></what-we-do>
    <main-footer></main-footer>
</template>

<script>
import mainSlider from '@/components/slider/main-slider.vue'
import sectionAbout from '@/components/about/section-about.vue'
import whatWeDo from '@/components/what-we-do/what-we-do.vue'
import mainHeader from '@/components/header/main-header.vue'
import mainFooter from '@/components/footer/main-footer.vue'
import eventTicker from '@/components/ticker/event-ticker.vue'
import countNumbers from '@/components/counter/count-numbers.vue'


export default {
    components: {
        mainHeader,
        mainSlider,
        sectionAbout,
        whatWeDo,
        mainFooter,
        eventTicker,
        countNumbers
    },
    data: () => ({
        welcomeModalShow: true,
        firstTime: false
    }),
    methods: {
        closeWelcomeModal() {
            this.firstTime = true
            this.welcomeModalShow = false
        },
    }
}
</script>

<style lang="scss">

</style>