import * as firebase from "firebase/app";
import UserService from "@/store/join-service";
import StateService from "@/store/state-service";
import EventService from "@/store/event-service";
import Message from "@/components/toastify/toastify";
import i18n from "@/i18n";
import config from "@/config";

const $t = i18n.global.t;

const state = () => ({
  loading: false,
  confirmationResult: null,
  verificationError: null,
  currentUser: null,
  userEmailVerified: false,
  states: null,
  cities: null,
});

const getters = {
  loading: (state) => state.loading,
  states: (state) => state.states,
  cities: (state) => state.cities,
  confirmationResult: (state) => state.confirmationResult,
  verificationError: (state) => state.verificationError,
  currentUser: (state) => state.currentUser,
  userEmailVerified: (state) => state.userEmailVerified,
};

const mutations = {
  CREATE_START(state) {
    state.loading = true;
  },
  CREATE_SUCCESS(state) {
    state.loading = false;
  },
  CREATE_ERROR(state) {
    state.loading = false;
  },
  VERIFICATION_CODE_START(state) {
    state.loading = true;
  },
  VERIFICATION_CODE_SUCCESS(state, payload) {
    state.confirmationResult = payload.confirmationResult || null;
    state.loading = false;
  },
  VERIFICATION_CODE_ERROR(state, payload) {
    state.confirmationResult = null;
    state.verificationError = payload;
    state.loading = false;
  },
  VERIFICATION_EMAIL_START(state) {
    state.loading = true;
  },
  VERIFICATION_EMAIL_SUCCESS(state) {
    state.loading = false;
  },
  VERIFICATION_EMAIL_ERROR(state) {
    state.loading = false;
  },
  LINKING_EMAIL_START(state) {
    state.loading = true;
  },
  LINKING_EMAIL_SUCCESS(state, { currentUser }) {
    state.currentUser = currentUser || null;
    state.loading = false;
  },
  LINKING_EMAIL_ERROR(state) {
    state.loading = false;
  },
  VERIFY_START(state) {
    state.loading = true;
  },
  VERIFY_SUCCESS(state, { currentUser, isEmailVerified }) {
    state.userEmailVerified = isEmailVerified || false;
    state.currentUser = currentUser || null;
    state.loading = false;
  },
  VERIFY_FAILED(state) {
    state.loading = false;
  },
  VERIFY_ERROR(state) {
    state.loading = false;
  },
  FETCH_STATE_START(state) {
    state.loading = true;
  },
  FETCH_STATE_SUCCESS(state, payload) {
    state.states = payload;
    state.loading = false;
  },
  FETCH_STATE_FAILD(state) {
    state.loading = false;
  },
  FETCH_CITIES_START() {
    // state.loading = true;
  },
  FETCH_CITIES_SUCCESS(state, payload) {
    state.cities = payload;
    // state.loading = false;
  },
  FETCH_CITIES_FAILD() {
    // state.loading = false;
  },
  SUBMIT_START(state) {
    state.loading = true;
  },
  SUBMIT_SUCCESS(state) {
    state.loading = false;
  },
  SUBMIT_ERROR(state) {
    state.loading = false;
  },
  AUTH_START(state) {
    state.loading = true;
  },
  AUTH_SUCCESS(state, payload) {
    state.currentUser = payload.currentUser || null;
    state.loading = false;
  },
  AUTH_ERROR(state) {
    state.currentUser = null;
    state.loading = false;
  },
};

const actions = {
  async sendVerificationCodeToUserPhone(
    { commit },
    { phoneNumber, appVerifier }
  ) {
    try {
      commit("VERIFICATION_CODE_START");
      console.log(phoneNumber);
      firebase.auth().languageCode = i18n.locale || 'ar'
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier);
      console.log("ConfirmationResult", confirmationResult);
      localStorage.setItem("verfId", confirmationResult.verificationId);
      localStorage.setItem("phoneNum", phoneNumber);
      window.confirmationResult = confirmationResult;
      // ...
      //   let confirmationResult = window.confirmationResult;
      commit("VERIFICATION_CODE_SUCCESS", {
        confirmationResult,
      });
      return true;
    } catch (error) {
      // eslint-disable-next-line
      grecaptcha.reset(window.recaptchaWidgetId);
      console.error("error", error);
      commit("VERIFICATION_CODE_ERROR", error);
      return false;
    }
  },
  async VerifyCodeFromUser({ commit }, code) {
    try {
      commit("VERIFY_START");
      let verId = localStorage.getItem("verfId");
      console.log("VERID", verId);
      let credential = await firebase.auth.PhoneAuthProvider.credential(
        verId,
        code
      );
      const credentials = await firebase
        .auth()
        .signInWithCredential(credential);
      console.log("credentials", credentials);
      const currentUser = credentials.user;
      commit("VERIFY_SUCCESS", { currentUser });
      return "verified";
    } catch (error) {
      commit("VERIFY_ERROR");
      console.log("error", error);
      return "error";
    }
    // const { confirmationResult } = state;
    // confirmationResult
    //   .confirm(code)
    //   .then((result) => {
    //     const user = result.user;
    //     if (user) {
    //       return true;
    //     }
    //   })
    //   .catch((error) => {
    //     // User couldn't sign in (bad verification code?)
    //     // ...
    //     console.log("error", error);
    //   });
  },
  async sendVerificationEmailLink({ commit }, email) {
    try {
      commit("VERIFICATION_EMAIL_START");
      firebase.auth().languageCode = i18n.locale || 'ar'
      let actionCodeSettings = {
        // url: "http://localhost:8080/finishVerification",
        url: config.redirectUrl,
        handleCodeInApp: true,
        // dynamicLinkDomain: "example.page.link",
      };
      await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
      localStorage.setItem("UserEmail", email);
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  },
  async checkEmailLink({ commit }, { link, email }) {
    try {
      commit("LINKING_EMAIL_START");
      console.log("EMAIL==> ", email);
      console.log("LINK==>", link);
      const credential =
        await firebase.auth.EmailAuthProvider.credentialWithLink(email, link);

      console.log("CREDENTIAL==>", credential);
      const userCredential = await firebase
        .auth()
        .currentUser.linkWithCredential(credential);
      console.log("USER_CREDENTIAL==>", userCredential);
      const currentUser = userCredential.user;
      console.log("CURRENT_USER==>", currentUser);
      commit("LINKING_EMAIL_SUCCESS", { currentUser });
      return true;
    } catch (error) {
      commit("LINKING_EMAIL_ERROR");
      console.error("error", error);
      Message.error($t(`firebaseErrors.${error.code}`) || error.message);
      return false;
    }
  },
  async doEmailVerified({ commit }) {
    try {
      console.log("called:doEmailVerified");
      // await service.checkEmailVerified();
      commit("VERIFY_START");
      // await firebase.auth().currentUser.reload();
      await firebase.auth().onAuthStateChanged((authenticationUser) => {
        // authenticationUser.emailVerified = true;
        console.log("AUTHENTICATION_USER==>", authenticationUser);
        console.log("EMAIL_VERIFIED==>", authenticationUser?.emailVerified);

        if (authenticationUser?.emailVerified) {
          const currentUser = authenticationUser;
          commit("VERIFY_SUCCESS", { currentUser, isEmailVerified: true });
          return true;
        } else {
          commit("VERIFY_FAILED");
          return false;
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      commit("VERIFY_ERROR");
      return false;
    }
  },
  async createMember({ commit, dispatch }, payload) {
    try {
      // debugger
      commit("CREATE_START");
      console.log("PAYLOAD==>>", payload);
      let result;

      switch (payload.type) {
        case "member":
          console.log("memberhere");
          result = await UserService.createMember(payload);
          break;
        case "volunteer":
          console.log("volunteerhere");
          result = await UserService.createVolunteer(payload);
          break;
        case "organizer":
          console.log("organizerhere");
          result = await UserService.createOrganizer(payload);
          break;
        case "sponsor":
          console.log("sponsorhere");
          result = await UserService.createSponsor(payload);
          break;
        default:
          break;
      }
      console.log("resutl", result);
      commit("CREATE_SUCCESS");
      dispatch("doSignout");
      if (result.status) {
        // Message.success($t("message.success.userCreated"));
        return true;
      } else {
        Message.error(result.error.message);
        return false;
      }
    } catch (error) {
      Message.error($t("message.error.wrong"));
      console.error(error);
      commit("CREATE_ERROR");
    }
  },
  async getStates({ commit }) {
    try {
      commit("FETCH_STATE_START");
      const states = await StateService.getStates();
      console.log("states", states);
      commit("FETCH_STATE_SUCCESS", states);
    } catch (error) {
      commit("FETCH_STATE_FAILD");
      console.error(error);
    }
  },
  async getCities({ commit }, stateId) {
    try {
      commit("FETCH_CITIES_START");
      console.log("STATE_ID", stateId);
      const cities = await StateService.getCities(stateId);
      console.log("cities", cities);
      commit("FETCH_CITIES_SUCCESS", cities);
    } catch (error) {
      commit("FETCH_CITIES_FAILD");
      console.error(error);
    }
  },
  async doJoinEvent({ commit }, payload) {
    try {
      // debugger
      commit("SUBMIT_START");
      console.log("PAYLOAD==>>", payload);
      const result = await EventService.joinEvent(
        "pf3AWVMLeWPPxFzcKRR3",
        payload
      );
      console.log("resutl", result);
      commit("SUBMIT_SUCCESS");
      if (result.status) {
        // Message.success($t("message.success.userCreated"));
        return true;
      } else {
        Message.error(result.error.message);
        return false;
      }
    } catch (error) {
      console.error(error);
      Message.error(error.message);
      commit("SUBMIT_ERROR");
    }
  },
  async doSignout({ commit }) {
    try {
      commit("AUTH_START");
      await firebase.auth().signOut();
      localStorage.clear();
      commit("AUTH_SUCCESS", {
        authenticationUser: null,
        currentUser: null,
      });
    } catch (error) {
      Message.error(error);
      commit("AUTH_ERROR");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
