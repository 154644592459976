<template>
    {{message}}
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'app-i18n',
    props: ['code'],
    setup(props) {
        const { t } = useI18n()
        const message = computed(() => t(props.code))
        return {
            message
        }
    }
}
</script>