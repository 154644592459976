export default {
  "app": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراسيا - الجالية العربية في أمريكا"])}
  },
  "routes": {
    "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اراسيا - الجالية العربية في أمريكا"])},
    "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قريبا"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!معا كواحد"])}
  },
  "ticker": {
    "event": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية ومهرجان العائلة والأصدقاء 👨‍👩‍👧‍👦"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت 29 أكتوبر 2022 ❤️"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلينا الآن وقم بالتسجيل 📝"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا"])}
    }
  },
  "welcomeModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بكم فى اراسيا"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعالية \"لمة العيلة العربية\" تجمّع الأهل والاصدقاء السبت 29 أكتوبر 2022 - 4 عصراً"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الأن"])}
  },
  "notificationPopup": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ !"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم !"])}
  },
  "message": {
    "success": {
      "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال الرمز الى هاتفك قم بتفقد الرسائل"])},
      "numberVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التأكد من رقم الهاتف"])},
      "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم ارسال بريد الكترونى قم بتفقده لتفعيل بريدك الإلكتروني"])},
      "userCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لانضمامك الينا"])}
    },
    "error": {
      "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما !"])},
      "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التفعيل خاطئ"])},
      "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الهاتف ليس مفعل"])},
      "form": {
        "fillFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكمل جميع البيانات"])},
        "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ادخل رقم بريدى صحيح"])},
        "nameLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم يجب ان يكون اكبر من او يساوى ٥ حروف"])},
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء قبول جميع الشروط والأحكام"])}
      }
    }
  },
  "emailVerification": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل التحقق من البريد الإلكتروني"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكمل ملء معلوماتك."])},
    "continueBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["واصل"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من عنوان بريدك الإلكتروني"])},
    "verifyBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال بريدك الإلكتروني لإكمال عملية التحقق."])}
  },
  "form": {
    "verPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعل رقم هاتفك"])},
    "verPhoneMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أجل أمانك ، تريد اراسيا التأكد من هويتك حقًا. سترسل اراسيا رسالة نصية تحتوي على رمز تحقق مكون من 6 أرقام."])},
    "verEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فعل بريدك الإلكتروني"])},
    "verEmailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من أجل أمانك ، تريد اراسيا التأكد من هويتك حقًا. سيرسل اراسيا بريدًا إلكترونيًا به رابط إلى بريدك الإلكتروني."])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أكمل بياناتك"])},
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بريدك الإلكتروني"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز البريدي"])},
      "familyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد أفراد الأسرة"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأطفال أقل من 8 سنوات"])},
      "over50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفراد العائلة فوق سن الخمسين"])},
      "hobbies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هوايات"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال الخبرة"])},
      "freeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد ساعات الفراغ لديك"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الولاية"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجنس"])},
      "patronType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الداعم"])},
      "memberType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العضوية"])},
      "sponsorshipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الدعم"])},
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أوافق على"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])}
    },
    "actions": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
      "sendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرسل الرمز"])},
      "verifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من كود"])},
      "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل بريد الكتروني"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])}
    }
  },
  "header": {
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل الينا"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بزيارتنا"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبرع"])}
  },
  "slider": {
    "slide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنضمامك إلينا هو أول خطوة للتعرف على المجتمع الأمريكي بشكل افضل"])},
    "slide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أراسيا - نساعد ونساند ونقدم يد العون لكل العرب في الولايات المتحدة الأمريكية"])},
    "slide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعرف على كل الفرص المتاحة للعمل والتعليم وأطلع على ثقافات مختلفة بإنضمامك لنا"])},
    "slide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا هو تقديم الخدمات والمساعدات للجالية العربية في أمريكا دون اعتبار للعرق أو الدين"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم الينا"])}
  },
  "about": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن اراسيا"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معا كواحد !"])},
    "titleImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعد اليوم"])},
    "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن منظمة غير هادفة للربح تسعى إلى مساعدة العرب ودعمهم للانخراط في المجتمع الأمريكي من خلال تقديم العديد من الخدمات والدعم والفرص والبرامج والمبادرات على النحو التالي..."])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توعية العرب بالثقافة الأمريكية ومساعدتهم على تحمل مسئولية مستقبلهم"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إقامة لقاءات تثقيفية لتنويرهم فيما يتعلق بالثقافات الأخرى من خلال اللقاءات الدورية وجلسات الإرشاد والمزيد من المبادرات. والبرامج و الاجتماعات"])},
    "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة المجتمع العربي على معرفة طريقه وتأهيله لدخول سوق العمل"])},
    "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم الخدمات والمساعدات للمحتاجين دون اعتبار للعرق أو الدين"])},
    "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توفير العديد من الأغراض للمحتاجين مثل الملابس والأحذية والأدوات المنزلية والطعام ... إلخ"])},
    "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء منظمة أراسيا \"الجاليه العربية فى أمريكا\" لأغراض خيرية، ونحن نرحب بجميع العرب المتواجدون في الولايات المتحدة الأمريكية من أي جنسيات بغض النظر عن العرق واللون والدين"])}
  },
  "programs": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا نقدم فى اراسيا"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج والمبادرات"])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دورات تدريبية وتثقيفية وندوات وورش عمل متعددة ومختلفة المجالات"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدات مالية والكثير من المنتجات والتبرعات الخيرية (طعام٫ ملابس٫ أثاث٫ أجهزة كهربائية)٫ واحتياجات المنزل المختلفة"])},
    "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فرص وظيفية وتدريبية ومنح للدراسة والتعليم والتدريب والبرامج التعليمية"])},
    "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الربط مع والتواصل مع الجهات المختلفة في شتى القطاعات (المالية, الطبية, التعليمية)٫٫٫إلخ"])},
    "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استقبال القادمين من مختلف الدول العربية والمساعدتهم للعيش والاستقرار في الولايات المتحدة الامريكية بدءا من الترحيب بهم وحتى استقرارهم معيشيا."])}
  },
  "footer": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن اراسيا"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن منظمة غير ربحية تسعى إلى مساعدة العرب ودعمهم للانخراط في المجتمع الأمريكي"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبرع"])}
    },
    "team": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق العمل"])},
      "list": {
        "1": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجلاء البغدادي"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (832) 228-2841"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للاعمال الخيرية"])}
        },
        "2": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ياسر عمار"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (713) 679-0782"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعمليات التشغيلية"])}
        },
        "3": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاني سويلم"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (832) 640-7602"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للتسويق والنمو"])}
        },
        "4": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهندا جمال"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (530) 609-8932"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للفعاليات والمبادرات"])}
        }
      }
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
      "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل الينا"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بزيارتنا"])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 - الجالية العربية في أمريكا - جميع الحقوق محفوظة"])}
  },
  "privacyPolicy": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإلتزامات والواجبات المعدة أدناه والتي تحكم سياسة إستخدام الموقع معدة بضوء القوانين والإرشادات الخاصة بسلامة إستخدام المواقع وعليه يرجى قراءة ذلك بعناية والامتثال لسياسة الخصوصية هذه، لأنه من المهم والواجب أن تعلم أنه لا يجوز لك إستخدام الموقع هذا إلا طبقاً لشروط الاستخدام، ولأغراض قانونية ولائقة فقط ."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخولك إلى الموقع يعد إقراراً بموافقتك على ما هو موضح في \"الشروط والأحكام\" و\"سياسة الخصوصية\". و في حال عدم موافقتك على بنود \"الشروط والأحكام\" و\"سياسة الخصوصية\"، يجب عليك التوقف عن استخدام هذا الموقع فوراً."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز استخدام أو تصفح الموقع بأي طريقة من شأنها خرق قوانين وأحكام وقواعد الممارسة داخل الولايات المتحدة الأمريكية أو أي نطاق سلطة قضائية تتصفح فيه الموقع."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز إستخدام هذا الموقع بأية طريقة غير قانونية أو إحتيالية، أو أن يكون لها غرض أو تأثير غير قانوني أو احتيالي."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز نشر أو إرسال أو بث أو أي وسيلة أخرى كانت و تتيح أية معلومات على هذا  الموقع وعلى سبيل المثال وليس الحصر: أن تكون مضرة أو تهديدية أو منافية للآداب العامة أو مزعجة أو فاحشة أو بذيئة أو افترائية أو بغيضة أو تحمل تمييزاً عنصرياً أو عرقياً أو غير قانونية من نواحٍ أخرى."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز تعمد استخدام أي برنامج إلكتروني أو وسيلة أو جهاز للتدخل أو محاولة التدخل إلكترونياً أو يدوياً بعملية التشغيل أو الأداء الوظيفي للموقع."])},
    "p7": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز الحصول (أو محاولة الحصول) على إذن دخول غير مصرح به ، أو التدخل في أو إتلاف أو تشويش:"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1)  أي جزء من أجزاء الموقع."])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(2)  أي جهاز أو شبكة يخزن عليها الموقع."])},
      "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(3)  أي برنامج إلكتروني يستخدم في نصوص الموقع."])},
      "li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(4)  أي جهاز أو شبكة أو أي برنامج إلكتروني يمتلكه أو يستخدمه أي طرف ثالث."])}
    },
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز مهاجمة الموقع عن طريق هجمات الحرمان من الخدمة أو هجمات حجب الخدمة الموزعة."])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز استخدام الموقع بطريقة تسبب أو ربما قد تسبب خرقاً لحقوق الموقع أو حقوق الطرف الثالث."])},
    "p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز التشويه أو التغيير أو التدخل في الواجهة الأمامية وهيكلية الموقع أو أي من الأشكال المنجزة على الموقع."])},
    "p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز القيام بأي تصرف يفرض حملاً كبيراً غير معقول أو غير متناسب على الموقع وبنيته."])},
    "p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز إعادة نسخ أو استخراج صور ، أو نسخ أو إعادة بيع أي جزء من اجزاء هذا الموقع  بإستثناء الحالات االمصرح عنها صراحة في الشروط والأحكام."])},
    "p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       أي خرق لأي من التعهدات المذكورة أعلاه يعد جريمة جنائية حسب القوانين المعمول بها عالميا، وبناءً عليه فسوف نبلغ سلطات تطبيق القانون المعنية عن أي عملية خرق، وسوف نتعاون مع هذه السلطات للكشف عن هويتك ، واتخاذ كافة التدابير اللازمة لتطبيق نصوص القوانين"])},
    "p113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       لا يجوز لك إنشاء أي رابط لصفحتنا الرئيسية أو لأي جزء من موقعنا الإلكتروني دون موافقة كتابية من قبلنا."])},
    "p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       كل الأشخاص الذين يتصفحون الموقع من خلال حساب الإنترنت الخاص بك يعتبرون على دراية بهذه الشروط وأنهم يمتثلون لها. وتعتبر أنت المسؤول عن اتخاذ أية إجراءات ضرورية للوصول إلى الموقع والتأكد من أن كل من يستخدم الشبكة الخاصة بك على دراية كافية بما سبق، ولا يخلي طرفك من المسؤوليات والتعهدات السابقة."])},
    "p15": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقوق الملكية الفكرية:"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       كل العلامات التجارية وحق النشر وحقوق ملكية قاعدة البيانات وحقوق الملكية الفكرية الأخرى (حقوق بروتوكول الإنترنت) في:"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(أ) المواد المنشورة على الموقع"])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ب) تصميم وتنظيم وتخطيط الموقع"])},
      "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ج) أي برنامج أو قانون برامج أساسية تمت إتاحته لتنزيل بيانات من الموقع (يشار إليها بعبارة \"برنامج\")، هي مواد مملوكة لنا و/أو المرخصين لنا ولا يتم منحك حصص أو أسهم ملكية طبقاً لشروط الاستخدام."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       باستثناء الحالات المنصوص عليها صراحة في شروط الاستخدام أو الخاصة بالجزء ذي الصلة من الموقع، لا يجوز لك القيام بما يلي دون الحصول على موافقة كتابية من طرفنا:"])},
      "li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. نسخ أو إعادة نسخ أو تعديل أو تغيير أو نشر أو بث أو توزيع أو عرض أو إعلان أو بيع أو نقل أو إرسال بأي صيغة أو بأي وسيلة أية مواد على الموقع أو أي برنامج، سواء كانت كاملة أو جزئية، ولا يجوز استخدام المواد لإنتاج أعمال أخرى مشتقة؛"])},
      "li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. استخدام أي جزء من المواد على الموقع لأغراض تجارية."])},
      "li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. إنشاء قاعدة بيانات بصيغة إلكترونية أو يدوية منظمة عن طريق تنزيل أو تخزين أي محتوى بشكل منظم."])},
      "li7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. تعديل النسخ الورقية أو الرقمية لأي مواد قمت بطباعتها أو تنزيلها بأي طريقة، ولا يجوز لك استخدام أي رسوم توضيحية أو صور أو مقاطع صوتية أو سمعية أو رسوم بيانية بشكل منفصل عن النص المرافق لها."])},
      "li8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. إزالة إشعارات حق النشر والعلامة التجارية (سواء كانت الخاصة بنا أو الخاصة بالمرخصين لنا أو المساهمين) عن أي نسخة من نسخ المحتوى طبقاً لشروط الاستخدام هذه."])},
      "li9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. توزيع أو عرض أو نسخ محتويات صفحات الموقع لصالح أي طرف ثالث بما في ذلك، على سبيل المثال وليس الحصر، \"تخزين\" أية مواد على الموقع بشكل مؤقت من أجل إتاحتها لطرف ثالث، و\"تصوير\" أي مواد على الموقع إلى موقع آخر."])},
      "li10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 -لا يجوز لك التدخل بإعدادات وخواص سلامة الموقع، أو منع أي شخص من الدخول إليه، أو تدمير أي معلومات عليه."])},
      "li11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8- يجوز لك مشاهدة أو نسخ أو تنزيل محتوى الموقع إلى القرص الصلب بحاسوبك، أو طباعته أو توزيعه أو أي جزء من ذلك، بشرط:"])},
      "subLi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(أ) أن يتم استخدم المحتوى للتزود بالمعلومات فقط."])},
      "subLi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ب) أن يستخدم المحتوى لأغراض غير تجارية أو مسموح بها على نحو خاص."])},
      "subLi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ج) ألا يتم دمج أي مواد من الموقع في أي أعمال أخرى، سواء كانت منشورة أو إلكترونية (سواء كان لمصلحتك أو لمصلحة طرف ثالث)."])},
      "subLi4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(د) يجب أن تشمل أي أعمال إعادة نسخ للمحتوى أو أي جزء منه على إشعارات حق النشر والعلامة التجارية."])}
    },
    "p16": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات التي نقوم بجمعها:"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتلقى الموقع المعلومات التي يتزوّد بها بشكل مباشر، مثل المعلومات الشخصية التي يزودنا بها المستخدم لدى الاتصال بنا، والمعلومات التي يجمعها الموقع من المستخدم بشكل تلقائي، مثل المعلومات التي يتم الحصول عليها من المتصفح أو الجهاز الخاص بالمستخدم. حيث نقوم تلقائياً بجمع معلومات عن عمليات وأنماط تصفح مستخدمينا. وتُستخدم هذه المعلومات لتحسين فهمنا لمستخدمينا والأشياء المفضلة لديهم. ويمكن أيضاً استخدامها لمساعدتنا في مراجعة استخدام الموقع وتوجيه فرص التحرير والإعلان والفرص التجارية."])}
    },
    "p17": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملفات تعريف الارتباط (كوكيز):"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       يتوجب عليك أن تعلم أنه من الممكن أن يتم جمع المعلومات والبيانات تلقائيا من خلال استخدام الملفات النصية (كوكيز). وهي ملفات نصية صغيرة يتم من خلالها حفظ المعلومات الأساسية التي يستخدمها موقع الشبكة من أجل تحديد الاستخدامات المتكررة. قد نستخدم هذه المعلومات من أجل متابعة السلوك وتجميع بيانات كلية من أجل تحسين الموقع، واستهداف الإعلانات وتقييم الفعالية العامة لمثل هذه الإعلانات."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-       قد تقدم أطراف ثالثة ملفات تعريف ارتباط (كوكيز) من خلال الموقع. وتُستخدم هذه الملفات للأغراض متنوعة، مثلاً: لخدمة الإعلان على الموقع وتعقب إذا كان المستخدمون ضغطوا على هذه الإعلانات أم لا. للتحكم في عدد مرات عرض إعلان محدد. لإحصاء عدد مستخدمين الموقع المجهولين...الخ."])}
    },
    "p18": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية معلومات المستخدم الخاصة بك:"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من جانبنا فإننا نعي معايير الصناعة ونستخدم تطبيقات الأمن من أجل حماية معلومات المستخدم من أي اختراق غير مشروع أو إساءة استخدام. لكن للأسف يعتبر إرسال البيانات عبر الإنترنت أمرا لا يمكن ضمانه على نحو كلي. ومع التزامنا التام بحماية معلومات المستخدم الخاصة بك، فإننا لا نستطيع ضمان أمان إرسال معلومات المستخدم الخاصة بك إلى الموقع. إن أي بيانات يتم إرسالها، بما في ذلك معلومات المستخدم الخاصة بك، وان استخدامك للموقع يكون دائماً على مسؤوليتك الكاملة."])}
    },
    "p19": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النقل الدولي للبيانات الشخصية:"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يكون جمع وإرسال البيانات الشخصية في أغلب الأحيان قاصراً على دولة واحدة. يجب أن تدرك أنك باستخدامك الموقع ومواقع شركاءنا أو أي موقع آخر يوجد رابطه على موقعنا، أنه يمكن معالجة ونقل بياناتك الشخصية إلى أية دول أخرى وأنك توافق على نقل ومعالجة بياناتك بهذه الطريقة."])}
    },
    "p20": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغييرات على سياسة الخصوصية، والخدمة:"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قد يتم تعديل شروط الخدمة، أو أي جزء منها، أو إضافة أو إزالة الشروط في أي وقت، ومثل هذه التعديلات والإضافات أو الحذف وسوف تظهر على الموقع فور نشرها، ويتحمل المستخدم مسؤولية تفقد الموقع بشكل دوري للاطلاع على أية تعديلات قد يتم إدخالها على شروط الاستخدام. ويعتبر استخدامك للموقع بعد نشر هذه التعديلات أو الإضافات قبولا من قبلك لهذه التعديلات والإضافات أو الحذف. وإذا كنت لا توافق على شروط الاستخدام المعدلة فلا يجوز لك الدخول إلى الموقع أو استخدامه، ويجب أن تتوقف عن استخدام هذا الموقع على الفور."])}
    },
    "p21": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسؤولية الموقع:"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم استبعاد كل الإقرارات والشروط والضمانات والأحكام والالتزامات غير المنصوصة صراحة في شروط الاستخدام هذه إلى أقصى حد يسمح به القانون."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ختاما - أنت بذلك توافق على تحمل كل مخاطر عدم الامتثال لسياسة استخدام الموقع والمعلومات الواردة فيه، وتتحمل كامل المسؤولية ومخاطر الخسارة الناجمة عن ذلك."])}
    }
  },
  "termsAndConditions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والاحكام"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- تسجيلك لحضورك الفعالية يعني موافقتك الكاملة وبإرادتك على كافة الشروط والأحكام الخاصة بالفعالية وكذلك مكان إقامة الحدث والمدينة التي سيقام بها الحدث"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- تسجيلك لحضور الفعالية واشتراكك في الحدث يعني موافقتك على أنك مسؤول مسئولية كاملة عن ممتلكاتك ومقتنياتك وعن سلامة اسرتك وافراد عائلة وان كل شخص مسؤول عن نفسه طوال ساعات الفعالية، وليس للمنظمين او الجهة المنظمة اية مسئولية عنك وعن اسرتك وعائلتك واغراضك ومقتنياتك وسلامتك "])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- باشتراكك وانضمامك وتسجيلك لحضور الفعالية والحدث فأنت توافق على ان تستلم رسائل نصية ورسومية على هاتفك المحمول وعبر تطبيق الواتساب كذلك وعبر البريد الإلكتروني الذي قمت بإدخاله"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- تسجيلك وانضمامك على الموقع الالكتروني يعني موافقتك على مشاركة بياناتك مع الجهات التسويقية والاعلانية التي نتعامل معها وكذلك مع الشركاء والرعاة من أجل أغراض دعائية وترويجية"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5- تسجيلك واشتراكك في الفعالية مجاني، ولا يقع مسئولية مالية او أدبية او قانونية على الجهة المنظمة في حال ان قام أحد بابتزازك مالياً او الحصول منك على مال"])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6- في حال اردت الحصول على مساعدة او في حال كان لديك استفسار او سؤال، يجب عليك التواصل مع الجهة المنظمة وممثليها الرسميين فقط ولسنا مسئولين عن تواصلك مع أي شخص اخر"])}
  },
  "eventAgenda": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج الحدث"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجاناً خلال تجمع العائلة والاصدقاء"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- سوف نقوم بتقديم جلسات تصوير مجانية ، حيث يمكنك أنت وعائلتك الحصول على جلسة تصوير مجانية حول البحيرة وفي المناطق المفتوحة"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- يتم توفير الماء والمشروبات الغازية والعصائر والكثير من الوجبات الخفيفة والحلويات مجانًا أثناءالتجمع"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- المراجيح وألعاب الأطفال المختلفة بالإضافة إلى ألعاب الكبار مثل تنس الطاولة وconnect4 و twister وكرة القدم"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- سوف نقوم بتوفير اكثر من 50 فرصة عمل ، حيث يمكنك تقديم سيرتك الذاتية وملء استمارة الطلب"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5- كوبونات وخصومات من المتاجر والشركات العربية وغيرها من الاعمال سوف نقوم بها"])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6- بمجرد وصولك الى مكان الفعالية، يرجى التسجيل للحصول على رقم خاص بالسحب على الجوائز الكبري تليفزيون حلة ضغط عربة أطفال"])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7- سوف نقوم بتوزيع هدايا مجانية وألعاب للأطفال"])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8- يحصل كبار السن علي هدايا مجانية"])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9- يوجد طاولات وكراسي لكبار السن"])}
  },
  "thankyou": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لتسجيلك ومرحبا بكم في ارسيا - الجالية العربية في أمريكا"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالانضمام إلينا ، ستكون واحدًا من 4،880،000 جنسية عربية تعيش في الولايات المتحدة الأمريكية ، وستحصل على المساعدة متى احتجت لذلك ، اتصل بنا على مدار الساعة طوال أيام الأسبوع على 5054-435 (530) 1+ "])},
    "copy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["حقوق النشر  2022"]), _normalize(["اراسيا \"الجالية العربية في أمريكا\""]), _normalize(["كل الحقوق محفوظة"])])}
  },
  "counter": {
    "needed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبادرة قيد التنفيذ"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
    },
    "country": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دول العربية"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])}
    },
    "connected": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتطوعون الحاليين"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["67"])}
    },
    "visitors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأعضاء الحاليين"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4000+"])}
    }
  },
  "octoberEvent": {
    "bannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهرجان العائلة والأصدقاء"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الحضور"])},
      "kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاطفال"])}
    },
    "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رعاة الحدث"])},
    "widget": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهرجان العائلة والأصدقاء"])},
      "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجلاء البغدادي"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوم"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعة"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقيقه"])},
      "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانية"])}
    },
    "details": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الحدث"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أهلا بكم في التجمع الثالث للعائلة والأصدقاء العرب في أمريكا ، حيث نلتقي مرة أخرى بالعديد من المفاجآت والأنشطة والهدايا والمسابقات. دعونا نتقابل ونتعرف على بعضنا البعض ونغني ونستمتع معًا ونستمتع بأوقاتنا مع بعضنا البعض"])}
    },
    "tabs": {
      "facilities": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرافق"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["663 مليون شخص يشربون المياه القذرة. تعلم كيف يمكن أن يؤدي الوصول إلى المياه النظيفة إلى تحسين الصحة وتعزيز الاقتصاد المحلي"])},
        "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة كبار الاستشاريين في المشاريع"])},
        "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة كبار الاستشاريين في المشاريع"])},
        "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك أفضل الممارسات والمعرفة."])},
        "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعاون مع التكنولوجيا وأمن المعلومات وشركاء الأعمال"])},
        "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن مشكلات الأداء ومعالجتها"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل حضورك للحدث"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل أسمك..."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدك الإلكتروني ..."])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم هاتفك..."])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رسالتك..."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
      },
      "mapLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الخريطة"])}
    },
    "mission": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهمة الحدث"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنقوم بالعديد من الأنشطة خلال اليوم لمساعدة المشاركين في زيادة البهجة والسرور باللعب وتوفير الطعام والماء والمشروبات الغازية"])}
    },
    "vision": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤية الحدث"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غايتنا هي ان نقوم بعمل حالة عامة من الترابط القوي بين جميع افراد الجالية العربية ونشر روح الحب والترابط"])}
    },
    "objective": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اهداف الحدث"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سننخرط في مجموعة متنوعة من الأنشطة على مدار اليوم لمساعدة المشاركين على زيادة روح الدعابة لديهم من خلال  الألعاب والطعام والماء والمشروبات الغازية"])}
    },
    "eventAgenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برنامج الحدث"])},
    "timeline": {
      "title0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترحيب و تحيه المدعوين"])},
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بوفيه مفتوح"])},
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الانضمام إلينا لتناول عشاء مشترك ، وإحضار طبق ترغب في مشاركته ، وسيحصل الشخص الذي لديه أكثر الأطباق اللذيذة على جائزة خاصة"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفلة عيد ميلاد شهر أكتوبر"])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا صادف عيد ميلادك في شهر أكتوبر ، فأنت مدعو للاحتفال معنا بهذه المناسبة."])},
      "subtitle21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدايا عيد الميلاد"])},
      "subtitle22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغاني ورقصة عيد الميلاد"])},
      "subcontent21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هدفنا هو رؤية الفرحة والسعادة في عيون الجميع وبالأخص مواليد شهر أكتوبر، لذلك سنقوم بتوزيع الكثير من الهدايا"])},
      "subcontent22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنقوم بالغناء جميعا على انغام (سنة حلوة يا جميل) والكثير من الفقرات الاخرى"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لكل من جعلوا الإختلاف هو سر التميز والنجاح"])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنكرم ونحتفل مع كل من نحبهم من ذوي الاحتياجات الخاصة والإعاقات."])},
      "subtitle31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["د. رحاب حمودة"])},
      "subtitle32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["د. محمد حجازي"])},
      "subcontent31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيفية إشراك ذوي الاحتياجات الخاصة في المجتمع وجعلهم أكثر نجاحًا في الحياة"])},
      "subcontent32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الهدايا وشهادات التقدير"])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الساحر والمهرج"])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض خاص للمهرج والساحر يتضمن مشاهد ترفيهية وممتعة."])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسابقة سمعّنا صوتك"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسابقة خاصة للمواهب الصوتية ، الكبار والصغار ، مع فرصة للفوز بجوائز رائعه"])},
      "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدبكة العربية (لبناني – فلسطيني – اردني)"])},
      "content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاركنا فقرة الدبكة العربية بالخطوة والموسيقى للأطفال والكبار بكل الجنسيات العربية"])},
      "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفلة تنكرية للهالوين"])},
      "content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنحتفل جميعًا بعيد الهالوين من خلال مسابقة أزياء مع الأصدقاء والعائلة ، وسيحصل أفضل مصمم أزياء على هدايا خاصة."])}
    }
  },
  "errorPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنت خارج الحدود."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة توجيه"])}
  },
  "firebaseErrors": {
    "auth/user-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسابك معطل"])},
    "auth/user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك"])},
    "auth/wrong-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك"])},
    "auth/weak-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور هذه ضعيفة جدًا"])},
    "auth/email-already-in-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني قيد الاستخدام بالفعل"])},
    "auth/invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تقديم عنوان بريد إلكتروني صالح"])},
    "auth/account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني مستخدم بالفعل لطريقة مصادقة مختلفة."])},
    "auth/credential-already-in-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الاعتماد قيد الاستخدام بالفعل"])},
    "auth/network-request-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل طلب الشبكة ، يرجى التحقق من اتصال الشبكة"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتطلب الاستعلام فهرسًا. يمكنك إنشائه هنا:"])},
    "FAILED_PRECONDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتطلب الاستعلام فهرسًا. يمكنك إنشائه هنا:"])},
    "auth/argument-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان بريد إلكتروني غير صحيح"])}
  }
}