<template>
  <div id="error-notify-content" class="toastify-content d-none d-flex">
    <font-awesome-icon icon="fa-solid fa-circle-xmark" color="red"/>
    <div class="ml-4 mr-4">
      <div class="font-medium" style="color: red !important;">{{ i18n('notificationPopup.error') }}</div>
      <div id="error-message-text" class="text-gray-600 mt-1">{{ i18n(message) }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    message: {
      type: String
    }
  },
  // created() {
  //   console.log(this.message)
  // },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss">
.toastify {
  position: absolute;
  top: 28px;
  background: none;
  box-shadow: 0 0 #0000;
  color: rgb(31 41 55);
  padding: 0;
  z-index: 9999;

  @media (max-width: 1140px) {
    max-width: calc(100% - 30px);
  }

  svg {
    font-size: 34px;
  }

  .toast-close {
    color: transparent;
    position: absolute;
    opacity: 1;
    display: flex;
    align-items: center;
    padding-right: 0.75rem;
    top: 0px;
    bottom: 0px;
    right: 0px;

    &:before {
      content: "×";
      display: block;
      font-size: 1.875rem;
      line-height: 2.25rem;
      color: rgb(75 85 99);
      font-weight: 300;
      margin: -0.375rem;
    }
  }
}

[dir=ltr] .toastify {
  right: 28px;
  left: auto;
}

[dir=rtl] .toastify {
  left: 28px;
  right: auto;
}

.toastify-content {
  padding: 1.25rem 3.5rem 1.25rem 1.25rem;
  background: #fff;
  color: rgb(75 85 99);
  border: 1px solid rgb(229 231 235);
  border-radius: 0.5rem;
  gap: 1rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

[dir=ltr] .toastify-content {
  padding-left: 1.25rem;
  padding-right: 3.5rem;
}

[dir=rtl] .toastify-content {
  padding-left: 3.5rem;
  padding-right: 1.25rem;
}

.toastify-content .toastify-avatar {
  width: 2.5em !important;
  height: 2.5em !important;
  margin: 0 16px 0 0 !important;
  display: inline-block;
  /* border-radius: 2px !important; */
}

[dir=ltr] .toast-close {
  position: absolute !important;
  top: 38px !important;
  bottom: 0px !important;
  right: 28px !important;
  left: unset !important;
  display: flex !important;
  align-items: center !important;
  /* padding-right: 0.75rem !important; */
  /* padding-left: unset !important; */
  color: transparent !important;
  opacity: 1 !important;
  /* z-index: -10; */
  /* height: 0; */
  width: 0;
  /* padding: 0; */
  background: transparent;
  border: none;
}

[dir=rtl] .toast-close {
  position: absolute !important;
  top: 38px !important;
  bottom: 0px !important;
  left: 28px !important;
  right: unset !important;
  display: flex !important;
  align-items: center !important;
  /* padding-right: 0.75rem !important; */
  /* padding-left: unset !important; */
  color: transparent !important;
  opacity: 1 !important;
  /* z-index: -10; */
  /* height: 0; */
  width: 0;
  /* padding: 0; */
  background: transparent;
  border: none;
}
</style>

