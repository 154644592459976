import gql from "graphql-tag";
import GraphqlClient from "@/graphql/client";
// import Message from "@/components/toastify/toastify";
import config from "@/config";

const graphqlClient = GraphqlClient.initApolloClient(`${config.backendUrl}/event`);

export default class EventService {
  static async joinEvent(eventId, { name, phoneNumber, kidsCount, guestsCount }) {
    // debugger
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOIN_EVENT($eventId: String!, $joinInput: JoinInput!) {
          joinEvent(eventId: $eventId, joinInput: $joinInput)
        }
      `,

      variables: {
        eventId: eventId,
        joinInput: {
          name,
          phoneNumber,
          kidsCount,
          guestsCount,
        },
      },
    });
    return response.data.joinEvent;
  }

  static async getEvent(eventId) {
    // debugger
    const response = await graphqlClient.query({
      query: gql`
        query JOIN_EVENT($eventId: String!) {
          findEventById(eventId: $eventId)
        }
      `,

      variables: {
        eventId: eventId
      },
    });
    return response.data.findEventById;
  }
}
