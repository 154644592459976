<template>
    <section class="main-slider">
        <swiper :spaceBetween="0" :effect="'fade'" :loop="true" v-if="isRTL" dir="rtl" :autoplay="{
            delay: 7000,
            disableOnInteraction: false,
        }" :pagination="{
    clickable: true,
}" :modules="modules" class="mySwiperAr">
            <swiper-slide v-slot="{ isActive }" v-for="slide in slides" :key="slide.id">
                <div class="image-layer"
                    :style="{ backgroundImage: isActive ? `url(${require('@/assets/images/' + slide.img)})` : '' }">
                </div>
                <div class="image-layer-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <div class="main-slider__content">
                                <h2 :class="{ 'active-h2': isActive }">
                                    <app-i18n :code="`slider.slide${slide.id}`" />
                                </h2>
                                <router-link to="/join-us" :class="isActive ? 'active-btn' : ''" class="btn">
                                    <app-i18n :code="`slider.action`" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <swiper :spaceBetween="30" :effect="'fade'" :loop="true" v-else dir="ltr" :autoplay="{
            delay: 7000,
            disableOnInteraction: false,
        }" :pagination="{
    clickable: true,
}" :modules="modules" class="mySwiper">
            <swiper-slide v-slot="{ isActive }" v-for="slide in slides" :key="slide.id">
                <div class="image-layer"
                    :style="{ backgroundImage: isActive ? `url(${require('@/assets/images/' + slide.img)})` : '' }">
                </div>
                <div class="image-layer-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <div class="main-slider__content">
                                <h2 :class="{ 'active-h2': isActive }">
                                    <app-i18n :code="`slider.slide${slide.id}`" />
                                </h2>
                                <router-link to="/join-us" :class="isActive ? 'active-btn' : ''" class="btn">
                                    <app-i18n :code="`slider.action`" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
// import ArrowIcon from '@/components/icons/arrow-right.vue'
import { ref } from "vue";

export default {
    components: {
        Swiper,
        SwiperSlide
        // ArrowIcon
    },
    created() {
        document.title = this.$t('app.title')
    },
    watch: {
        isRTL() {
            document.title = this.$t('app.title')
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar'
        }
    },
    setup() {
        const slides = ref([
            { id: 1, img: 'slider11.jpg' },
            { id: 2, img: 'slider12.jpg' },
            { id: 3, img: 'slider13.jpg' },
            { id: 4, img: 'slider14.jpg' }
        ])
        return {
            slides,
            modules: [EffectFade, Navigation, Pagination, Autoplay],
        };
    },
};
</script>
<style lang="scss">
.main-slider {
    overflow: hidden;
    position: relative;
    // font-family: Nunito, sans-serif;
    // margin-top: 137px;
    height: calc(100vh - 146px);


    .swiper {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        // height: 100vh;
        padding: 0;
        z-index: 1;
        height: 100%;

        .image-layer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-repeat: no-repeat;
            background-size: cover;
            transform: scale(1);
            filter: grayscale(100%);
            transition: transform 7s ease;
            z-index: 1;
        }

        .image-layer-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: rgba(31, 34, 48, .7);
            z-index: 2;
        }

        .container {
            position: relative;
            z-index: 30;
            // padding-bottom: 190px;
            // padding-top: 119px;
            padding-bottom: 0;
            padding-top: 0;
            height: 100%;
            display: flex;
            align-items: center;

            .main-slider__content {
                position: relative;
                display: block;

                @media (max-width: 990px) {
                    padding: 0 34px;
                }

                p {
                    font-size: 40px;
                    color: #fff;
                    font-family: reeyregular;
                    font-weight: 400;
                    line-height: 50px;
                    margin: 0 0 19px;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(100px);
                    transition: transform 2s ease, opacity 2s ease, color .5s ease, background .5s ease;
                }

                h2 {
                    margin: 0 0 37px;
                    color: #fff;
                    font-weight: 900;
                    font-size: 54px;
                    line-height: 1.6;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(120px);
                    transition: transform 2s ease, opacity 2s ease;

                    @media (max-width: 990px) {
                        font-size: 34px;
                    }

                    @media (max-width: 600px) {
                        font-size: 24px;
                    }
                }

                .btn {
                    display: inline-block;
                    vertical-align: middle;
                    border: none;
                    outline: none !important;
                    background-color: #FF8C40;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 900;
                    font-size: 18px;
                    font-weight: 800;
                    padding: 20px 48px;
                    opacity: 0;
                    transform: translateY(100px);
                    transition-delay: 2.5s;
                    transition: transform 2s ease, opacity 2s ease, color .5s ease, background .5s ease;
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;

                    svg {
                        width: 18px;
                        fill: #fff;
                    }
                }
            }
        }
    }

    .active-p {
        visibility: visible !important;
        opacity: 1 !important;
        transform: translate(0) !important;
    }

    .active-h2 {
        visibility: visible !important;
        opacity: 1 !important;
        transform: translate(0) !important;
    }

    .active-btn {
        visibility: visible !important;
        opacity: 1 !important;
        transform: translate(0) !important;
    }

    .swiper-pagination {
        z-index: 10;
        bottom: 51px;
        left: 50%;
        width: 100%;
        max-width: 1170px;
        transform: translate(-50%, 0);
        text-align: center;
        display: flex;
        justify-content: center;

        // @media (max-width: 990px) {
        // }

        .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            opacity: .5;
            background-color: #fff;
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
            opacity: 1;
        }

    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        left: 1%;
        top: 55%;
        width: 63px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #294BAC;
        background: #fff;
        border-radius: 50%;
        opacity: 1;
        margin: 0;
        text-align: center;
        transition: all .5s ease;
        transform: translateY(-50%);

        @media (max-width: 600px) {
            display: none;
        }
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: 1%;
        top: 45%;
        width: 63px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #294BAC;
        background: #fff;
        border-radius: 50%;
        opacity: 1;
        margin: 0;
        text-align: center;
        transition: all .5s ease;
        transform: translateY(-50%);

        @media (max-width: 600px) {
            display: none;
        }
    }

    .mySwiperAr {

        .swiper-button-prev,
        .swiper-button-next {
            right: 1%;
            left: auto;
        }
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        content: '\2190';
        font-size: 26px;
    }

    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
        content: '\2192';
        font-size: 26px;
    }
}
</style>