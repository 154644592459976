import gql from "graphql-tag";
import GraphqlClient from "@/graphql/client";
// import Message from "@/components/toastify/toastify";
import config from "@/config";
import firebaseInit from "@/firebase/firebase-init";

const graphqlClient = GraphqlClient.initApolloClient(
  `${config.backendUrl}/user`
);

export default class UserService {
  static init() {
    return firebaseInit();
  }
  static async getStates() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          listStates {
            id
            name
          }
        }
      `,
    });
    return response.data;
  }

  static async createMember({
    email,
    phoneNumber,
    city,
    state,
    zipCode,
    familyMembersCount,
    fullName,
    gender,
    overFiftyCount,
    underEightCount,
    type,
  }) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_USER($userInput: UserInput!) {
          createUser(userInput: $userInput)
        }
      `,
      variables: {
        userInput: {
          email,
          phoneNumber,
          city,
          state,
          zipCode,
          familyMembersCount,
          fullName,
          gender,
          overFiftyCount,
          underEightCount,
          type,
        },
      },
    });
    return response.data.createUser;
  }
  static async createVolunteer({
    email,
    phoneNumber,
    city,
    state,
    zipCode,
    fullName,
    gender,
    type,
    fieldOfExperience,
    hobbies,
  }) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_USER($userInput: UserInput!) {
          createUser(userInput: $userInput)
        }
      `,
      variables: {
        userInput: {
          email,
          phoneNumber,
          city,
          state,
          zipCode,
          fullName,
          gender,
          type,
          fieldOfExperience,
          hobbies,
        },
      },
    });
    return response.data.createUser;
  }
  static async createOrganizer({
    email,
    phoneNumber,
    city,
    state,
    zipCode,
    fullName,
    gender,
    type,
    freeHours,
    fieldOfExperience,
  }) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_USER($userInput: UserInput!) {
          createUser(userInput: $userInput)
        }
      `,
      variables: {
        userInput: {
          email,
          phoneNumber,
          city,
          state,
          zipCode,
          fullName,
          gender,
          type,
          freeHours,
          fieldOfExperience,
        },
      },
    });
    return response.data.createUser;
  }
  static async createSponsor({
    email,
    phoneNumber,
    city,
    state,
    zipCode,
    fullName,
    gender,
    sponsorType,
    sponsorshipType,
    type,
  }) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_USER($userInput: UserInput!) {
          createUser(userInput: $userInput)
        }
      `,
      variables: {
        userInput: {
          email,
          phoneNumber,
          city,
          state,
          zipCode,
          fullName,
          gender,
          sponsorType,
          sponsorshipType,
          type,
        },
      },
    });
    return response.data.createUser;
  }
}
