import gql from "graphql-tag";
import GraphqlClient from "@/graphql/client";
import config from "@/config";

const graphqlClient = GraphqlClient.initApolloClient(
  `${config.backendUrl}/state`
);
export default class StateService {
  static async getStates() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          listStates {
            id
            name
          }
        }
      `,
    });
    return response.data.listStates;
  }

  static async getCities(stateId) {
    console.log("stateid", stateId);
    const response = await graphqlClient.query({
      query: gql`
        query ($stateId: String!) {
          listStateCities(stateId: $stateId) {
            id
            name
          }
        }
      `,
      variables: {
        stateId: stateId,
      },
    });
    return response.data.listStateCities;
  }
}
