import { createStore } from "vuex";
import joinForm from './join-form'
import eventStore from './event-store'

export default createStore({
  modules: {
    eventStore,
    joinForm
  }
});


// const store = createStore({
//   modules: {
//     joinForm,
//     eventFrom
//   }
// })

// export function useStore() {
//   return store
// }

// export default store