export default {
  "app": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracia - Arab community in America"])}
  },
  "routes": {
    "app": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Aracia"]), _normalize(["Arab community in America"])])},
    "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together as one!"])}
  },
  "ticker": {
    "event": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family and Friends Festival 👨‍👩‍👧‍👦"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Saturday 29 October 2022 ❤️"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us now and register now 📝"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Here"])}
    }
  },
  "welcomeModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Aracia"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The \"Arab Family gathering \" event brings together family and friends Saturday October 29, 2022 - 4 pm"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])}
  },
  "message": {
    "success": {
      "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Sent to your phone check your SMS"])},
      "numberVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number verified successfully"])},
      "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email sent to you check your Inbox"])},
      "userCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank for joining us"])}
    },
    "error": {
      "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong!"])},
      "invalidCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Verification code"])},
      "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number is not verified"])},
      "form": {
        "fillFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill All Fields"])},
        "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid zip code"])},
        "nameLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name must be greater or equal to 5 characters"])},
        "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Accept All Terms And Conditions"])}
      }
    }
  },
  "notificationPopup": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error !"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success !"])}
  },
  "emailVerification": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Verification Completed"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue filling your information."])},
    "continueBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Email Address"])},
    "verifyBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email to complete verification process."])}
  },
  "form": {
    "verPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Phone Number"])},
    "verPhoneMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your security, Aracia wants to make sure it’s really you. Aracia will send a text message with a 6-digit verification code."])},
    "verEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Your Email"])},
    "verEmailMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your security, Aracia wants to make sure it’s really you. Aracia will send an email with a Link to your email."])},
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your information"])},
    "label": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
      "familyMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of family members"])},
      "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children under 8"])},
      "over50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family member over 50"])},
      "hoppies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppies"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience field"])},
      "freeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much free hours you have"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "patronType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patron Type"])},
      "memberType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MemberShip Type"])},
      "sponsorshipType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship Type"])},
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
      "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
    },
    "actions": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "sendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Code"])},
      "verifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Code"])},
      "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "header": {
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Anytime"])},
    "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
    "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Office"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])}
  },
  "slider": {
    "slide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joining us is the first step getting to know American society better."])},
    "slide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracia - We help, support and provide a helping hand to all Arabs in the United States of America"])},
    "slide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn about all the opportunities available for work and education and learn about different cultures by joining us"])},
    "slide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal is to provide services and assistance to the Arab community in America regardless to race or religion"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us"])}
  },
  "about": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Aracia"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together as one!"])},
    "titleImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helping Today"])},
    "about1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a non-profit organization that seeks to help and support Arabs to integrate into American society by providing many services, support, opportunities, programs and initiatives as follows..."])},
    "about2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aracia's \"Arab Community in America\" is organized exclusively for charitable purposes and we welcome all Arabs from any nationalities who live in the US."])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teaching the Arabs about the American culture and helping them take responsibility for their future"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing educational gatherings to enlighten them in respect of other cultures through periodic gatherings, counseling sessions, and more initiatives, and social programs"])},
    "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assist the Arab community to find their path and be qualified to enter the labor market"])},
    "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering services and assistance to people in need with no regard to race or religion"])},
    "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing many items for those in need such as clothes, shoes, household items, food...etc."])}
  },
  "programs": {
    "sectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do we offer in Aracia"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programs and initiatives"])},
    "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training and educational courses, seminars and workshops in various fields"])},
    "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial aid and a lot of charitable products and donations (food, clothes, furniture, electrical appliances), and various household needs."])},
    "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career and training opportunities, scholarships for study, education, training and educational programs"])},
    "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linking and communicating with different agencies in various sectors (financial, medical, educational)...etc."])},
    "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving arrivals from various Arab countries and helping them to live and settle in the United States of America, starting from welcoming them until their stability in life."])}
  },
  "footer": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Aracia"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a non-profit organization that seeks to help and support Arabs to engage in American society"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])}
    },
    "team": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Team"])},
      "list": {
        "1": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naglaa Al Boghdadi"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (832) 228-2841"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Charity Request"])}
        },
        "2": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yasser Ammar"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (713) 679-0782"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Operation"])}
        },
        "3": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hany Sewilam"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (832) 640-7602"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Marketing & Growth"])}
        },
        "4": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shahenda Gamal"])},
          "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+1 (530) 609-8932"])},
          "dep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Events & Initiatives"])}
        }
      }
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call Anytime"])},
      "sendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
      "visit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Office"])}
    },
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 - Arab Community in America - All rights reserved"])}
  },
  "privacyPolicy": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The obligations and duties prepared below that govern the use of the site policy are prepared in the light of the laws and guidelines for the safety of using the sites, and therefore please read that carefully and comply with this privacy policy, because it is important and obligatory for you to know that you may not use this site except in accordance with the terms of use, For lawful and decent purposes only ."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your access to the site constitutes an acknowledgment of your agreement to what is described in the \"Terms and Conditions\" and the \"Privacy Policy\". If you do not agree to the terms of the \"Terms and Conditions\" and the \"Privacy Policy\" You must stop using this website immediately ."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- You may not use or browse the site in any way that would violate the laws, provisions and rules of practice within the United States of America or any jurisdiction in which you are browsing the site ."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- This website may not be used in any way that is unlawful or fraudulent, or has an unlawful or fraudulent purpose or effect."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Any information may not be published, transmitted, broadcast or made available on this site , including, but not limited to: to be harmful, threatening, immoral, disturbing, obscene, obscene, slanderous or hateful. or racially or ethnically discriminatory or otherwise unlawful ."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- It is not permissible to intentionally use any electronic program, method or device to interfere or attempt to interfere electronically or manually with the operation or functionality of the website ."])},
    "p7": {
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- It is not permissible to obtain (or attempt to obtain) an unauthorized entry permit, or to interfere with, damage or disrupt :"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1) any part of the website ."])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(2) any device or network on which the website is stored."])},
      "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(3) any electronic program used in the texts of the site."])},
      "li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(4) any device, network or any electronic software owned or used by any third party."])}
    },
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website may not be attacked by denial-of-service attacks or distributed denial-of-service attacks."])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- The site may not be used in a way that causes, or may cause, a breach of the site's rights or the rights of a third party ."])},
    "p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- It is not permissible to distort, alter or interfere with the front end and structure of the site or any of the forms made on the site ."])},
    "p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- No action shall be taken that imposes an unreasonable or disproportionately large load on the site and its structure ."])},
    "p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- It is not permissible to reproduce or extract images, copy or resell any part of this site, except for the cases expressly authorized in the terms and conditions ."])},
    "p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Any breach of any of the above-mentioned undertakings is a criminal offense according to the laws in force globally, and accordingly we will inform the relevant law enforcement authorities of any breach, and we will cooperate with these authorities to reveal your identity, and take all necessary measures to implement the provisions of laws "])},
    "p113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- You may not create any link to our home page or any part of our website without our written consent ."])},
    "p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- All persons browsing the Site through your Internet account are deemed to be aware of these Terms and to comply with them. You are responsible for taking any necessary measures to access the Site and for ensuring that everyone who uses your network is adequately aware of the previously, and your party does not absolve you of the previous responsibilities and commitments ."])},
    "p15": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intellectual property rights :"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- all trademarks, copyright, database property rights and other intellectual property rights (Internet Protocol rights) in :"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(a) the material published on the site"])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(b) designing, organizing and planning the site"])},
      "li3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(c) any software or underlying software code that is made available to download data from the Site (\"Software \"), is material owned by us and/or our licensors and you are not granted shares or ownership in accordance with the Terms of Use ."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Except as expressly provided in the Terms of Use or the relevant part of the Site, you may not do the following without our written consent :"])},
      "li4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. copy, reproduce, modify, alter, publish, broadcast, distribute, display, advertise, sell, transmit or transmit in any form or by any means any material on the Site or any Program, whether in whole or in part; The materials may not be used to produce other derivative works"])},
      "li5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Use any part of the materials on the site for commercial purposes ."])},
      "li6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Creating a database in an organized electronic or manual format by downloading or storing any content in an organized manner ."])},
      "li7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Modify paper or digital copies of any materials you have printed off or downloaded in any way, and you may not use any illustrations, photographs, audio or audio clips, or graphics separately from the accompanying text ."])},
      "li8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Remove the copyright and trademark notices (whether our own, our licensors or contributors) from any copy of the Content in accordance with these Terms of Use ."])},
      "li9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Distribute, display or copy the contents of the pages of the Site for the benefit of any third party including, without limitation, \"storing\" any materials on the Site temporarily in order to make it available to a third party, and \"photocopying\" \"Any material on the site to another site ."])},
      "li10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 - You may not interfere with the site's safety settings and features, prevent anyone from entering it, or destroy any information on it ."])},
      "li11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. You may view, copy, download, print, or distribute the content of the Website to your computer’s hard disk, or any part thereof, provided :"])},
      "subLi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(a) that the content is used for information purposes only ."])},
      "subLi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(b) use the Content for non-commercial or specifically permitted purposes ."])},
      "subLi3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(c) not to incorporate any material from the Site into any other work, whether published or electronic (whether for your own benefit or for the benefit of a third party) ."])},
      "subLi4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(d) any reproduction of the Content or any part thereof shall include the copyright and trademark notices ."])}
    },
    "p16": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information we collect :"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Site receives information that it provides directly, such as personal information that the user provides us when contacting us, and information that the Site collects from the user automatically, such as information obtained from the user’s browser or device. Where we automatically collects information about our users' browsing patterns and processes. This information is used to improve our understanding of our users and their preferences. It may also be used to help us review site usage and direct editorial, advertising and commercial opportunities ."])}
    },
    "p17": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cookies: "])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- You should know that information and data may be collected automatically through the use of text files (cookies). They are small text files in which basic information used by the website is stored in order to determine repeated uses. We may use This information is used in order to track behavior and compile aggregate data in order to improve the site, target advertisements and evaluate the overall effectiveness of such advertisements."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Third parties may serve cookies through the site. These cookies are used for various purposes, for example: to serve advertising on the site and to track whether users have clicked on these ads or not. To control the number of times a specific advertisement is shown .to count the number of anonymous users of the site...etc ."])}
    },
    "p18": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection of your user information :"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For our part, we are aware of industry standards and use security applications in order to protect user information from any unlawful penetration or misuse. Unfortunately, transmission of data over the Internet is not completely guaranteed. With our full commitment to protect user private information With you, we cannot guarantee the security of the transmission of your user information to the Site. Any data transmitted, including your user information, and your use of the Site is always at your own risk ."])}
    },
    "p19": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Transfer of Personal Data :"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collection and transmission of personal data is often not limited to one country. You understand that by using the Site, our partner sites or any other site linked on our Site, that your personal data may be processed and transferred to any other countries and that you agree to the transfer of personal data to any other countries. and treat your data in this way ."])}
    },
    "p20": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes to the Privacy Policy, and the Service :"])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Terms of Service, or any part thereof, or adding or removing the Terms at any time, and such modifications, additions or deletions will appear on the site immediately upon their publication, and the user bears the responsibility to check the site periodically for any modifications that may be They are entered into the Terms of Use. Your use of the Site after posting such modifications or additions shall be deemed your acceptance of such modifications, additions or deletions. If you do not agree to the modified Terms of Use, you may not access or use the Site, and you must stop using this Site immediately. ."])}
    },
    "p21": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Responsibility :"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All representations, conditions, warranties, provisions and obligations not expressly set forth in these Terms of Use are excluded to the fullest extent permitted by law ."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In conclusion - you hereby agree to bear all risks of non-compliance with the policy of using the site and the information contained therein, and bear all responsibility and risk of loss resulting therefrom ."])}
    }
  },
  "termsAndConditions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- Registering for your attendance at the event means your full and voluntary acceptance of all the terms and conditions of the event, as well as the venue of the event and the city in which the event will be held."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- Your registration to attend the event and your participation in the event means your agreement that you are fully responsible for your property and belongings and for the safety of your family and family members, and that each person is responsible for himself throughout the hours of the event, and the organizers or the organizer has no responsibility for you, your family, your family, your purposes, your belongings and your safety."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- By subscribing, joining and registering to attend the event, you agree to receive text and graphic messages on your mobile phone, through the WhatsApp application as well, and through the email you entered."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- Your registration and joining on the website means your agreement to share your data with the marketing and advertising agencies we deal with, as well as with partners and sponsors for advertising and promotional purposes."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5- Your registration and participation in the event is free, and there is no financial, moral or legal responsibility on the organizer in the event that someone blackmails you financially or obtains money from you."])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6- If you want to get help or if you have a query or question, you should contact the organizer and its official representatives only, and we are not responsible for your communication with anyone else."])}
  },
  "eventAgenda": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Agenda"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free during Family and Friends Gathering"])},
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1- We will offer free photo sessions, where you and your family can get a free photoshoot around the lake and in open areas."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2- Water, soft drinks, juices, lots of snacks, and desserts are provided for free during the event day."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3- Park will provide swings and children's games and we will provide adult games such as table tennis and connect4, twister, and football \"according to the park law and their conditions\""])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4- We will provide more than 50 job opportunities, where you can submit your CV and fill out the application form"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5- Coupons and discounts book will be provided from Arab stores and companies and other businesses "])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6- Once you arrive at the event venue, please register for a chance to be the winner and get our WOW Gifts (TV, Pressure Cooker, Baby, Stroller)"])},
    "p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7- We will pass out a lot of free gifts and toys for children"])},
    "p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8- There will be special assistance for the elderly, and they will be given special gifts as well"])},
    "p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9- There will be tables and chairs for the elderly"])}
  },
  "thankyou": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your registration, and welcome to Aracia “Arab Community in America”"])},
    "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By joining us, you will be one of 4,880,000 Arab nationality living in the USA, and you will get help whenever you need, call us 24/7 at +1 (530) 435-5054"])},
    "copy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Copyright 2022"]), _normalize(["Aracia \"Arab Community in America\""]), _normalize(["All Rights Reserved"])])}
  },
  "counter": {
    "needed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives in progress"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12"])}
    },
    "country": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arab countries"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22"])}
    },
    "connected": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Volunteers"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["67"])}
    },
    "visitors": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Members"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4000+"])}
    }
  },
  "octoberEvent": {
    "bannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family & Friends Festival"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many guests"])},
      "kids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many kids"])}
    },
    "sponsors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event sponsors"])},
    "widget": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family & Friends Festival"])},
      "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naglaa Al-Boghdady"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
      "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
      "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
      "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])}
    },
    "details": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Details"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the third gathering of Arab family and friends in America, where we meet again with many surprises, activities, gifts, and competitions. Let’s meet and get to know each other and sing and have fun together and enjoy our times with each other"])}
    },
    "tabs": {
      "facilities": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["663 million people drink dirty water. Learn how access to clean water can improve health, boost local econom mies. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore ete dolore magna aliqua."])},
        "list1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assisting senior consultants in projects"])},
        "list2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assisting senior consultants in projects"])},
        "list3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share best practices and knowledge."])},
        "list4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborate with technology, informations security, and business partners"])},
        "list5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find and address performance issues."])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register to event"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Name..."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Email..."])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your phone Number..."])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Message..."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      },
      "mapLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map Location"])}
    },
    "mission": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Mission"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doing many activities during the day to help participants increase Their funny moment with play, banter, food, water, and soft drinks "])}
    },
    "vision": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Vision"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We aim to make a general case of strong interdependence between all members of the Arabian community and to spread the spirit of love."])}
    },
    "objective": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Objective"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will engage in a variety of activities throughout the day to help participants heighten their sense of humor through games, banter, food, water, and soft drinks."])}
    },
    "eventAgenda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Agenda"])},
    "timeline": {
      "title0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcoming and greeting the guest"])},
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Buffet"])},
      "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please join us for a potluck dinner, bring a dish you would like to share, and the person with the most delicious dish will get a special prize."])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October birthday party"])},
      "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your birthday falls in the month of October, you are invited to celebrate with us on that occasion."])},
      "subtitle21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BirthDay Gifts"])},
      "subtitle22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas songs and dance"])},
      "subcontent21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal is to see joy and happiness in everyone's eyes, especially those born in October, so we will distribute a lot of gifts"])},
      "subcontent22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will all sing to the tunes of (Happy New Year, beauty) and many other activities."])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you to everyone who made difference the secret of excellence and success"])},
      "content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will honor and celebrate with everyone we love with special needs and disabilities."])},
      "subtitle31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr. Rehab Hamouda"])},
      "subtitle32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dr. Muhammad hijazzi"])},
      "subcontent31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to engage people with special needs in society and make them more successful in life"])},
      "subcontent32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution of gifts and certificates of appreciation"])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Magician and The Clown"])},
      "content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A special show for the clown and the magician, which includes entertainment and fun scenes."])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's Hear Your Voice Contest"])},
      "content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A special competition for voice talents, adults and children, with the opportunity to win WOW prizes"])},
      "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arab Dabke (Lebanese - Palestinian - Jordanian)"])},
      "content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share with us the Arabic Dabkeh with step and music for children and adults of all Arab nationalities"])},
      "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halloween Costume Party"])},
      "content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will all celebrate Halloween through a costume contest with friends and family, and the best costumer will get special gifts."])}
    }
  },
  "errorPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're beyond the borders."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reroute"])}
  },
  "firebaseErrors": {
    "auth/user-disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is disabled"])},
    "auth/user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we don't recognize your credentials"])},
    "auth/wrong-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we don't recognize your credentials"])},
    "auth/weak-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is too weak"])},
    "auth/email-already-in-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already in use"])},
    "auth/invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a valid email"])},
    "auth/account-exists-with-different-credential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already in use for a different authentication method."])},
    "auth/credential-already-in-use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credentials are already in use"])},
    "auth/network-request-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network request failed, Please check your internet connection"])},
    "9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The query requires an index. You can create it here: ", _interpolate(_list(0))])},
    "FAILED_PRECONDITION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The query requires an index. You can create it here: ", _interpolate(_list(0))])},
    "auth/argument-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email!"])}
  }
}