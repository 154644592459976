<template>
  <div class="switch" :class="ar ? 'switch-ar' : ''">
    <!-- <input id="language-toggle" class="check-toggle check-toggle-round-flat" type="checkbox"
        @click="doChangeLanguage()" />
      <label for="language-toggle"></label>
      <span :class="isArabic ? 'on' : 'off'">AR</span>
      <span :class="isArabic ? 'off' : 'on'">EN</span> -->
    <img src="@/assets/images/ar-flag.png" @click="doChangeLanguage()" v-if="$i18n.locale === 'en'" class="flag-icon">
    <img src="@/assets/images/us-flag.png" @click="doChangeLanguage()" v-else class="flag-icon">
  </div>
</template>

<script>
export default {
  name: 'app-toggle-language',
  data: () => ({
    ar: localStorage.getItem('language'),
    isArabic: true,
    // value: i18n.locale
  }),
  methods: {
    doChangeLanguage() {
      const language = this.$i18n.locale == 'ar' ? 'en' : 'ar'
      this.$i18n.locale = language
      localStorage.setItem('language', language)
      console.log(language)
      const lang = this.$route.params.locale
      let route = ''
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${language}${this.$route.fullPath.substring(3)}`
      } else {
        route = `/${language}${this.$route.fullPath}`
      }
      if (language == 'ar') {
        this.ar = true
        document.body.setAttribute('dir', 'rtl')
        document.documentElement.setAttribute('lang', 'ar')
      } else {
        this.ar = false
        document.body.setAttribute('dir', 'ltr')
        document.documentElement.setAttribute('lang', 'en')
      }
      this.$router.push(route)
    }

  },
  created() {
    this.isArabic = localStorage.getItem('language') == 'ar'
  },
  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.ar = true
    } else {
      this.ar = false
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  // position: absolute;
  // top: 50%;
  // right: -3%;
  display: inline-block;
  margin: 0 5px;
  // transform: translateY(-50%);
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  width: 50px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #fff; */
  padding: 6px;
  cursor: pointer;
}

.flag-icon {
  width: 100%;
}

.switch-ar {
  right: auto;
  left: -3%;
}

.switch>span {
  position: absolute;
  top: 9px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked~.off {
  color: #FF8C40;
}

input.check-toggle-round-flat:checked~.on {
  color: #fff;
}

.switch>span.on {
  left: 0;
  padding-left: 2px;
  color: #FF8C40;
}

.switch>span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.check-toggle+label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat+label {
  padding: 2px;
  width: 99px;
  height: 35px;
  background-color: #FF8C40;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat+label:before,
input.check-toggle-round-flat+label:after {
  display: block;
  position: absolute;
  content: '';
}

input.check-toggle-round-flat+label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #FF8C40;

  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}

input.check-toggle-round-flat+label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

// input.check-toggle-round-flat:checked + label {
// }

input.check-toggle-round-flat:checked+label:after {
  margin-left: 44px;
}
</style>
