import EventService from "@/store/event-service";
import Message from "@/components/toastify/toastify";

const state = () => ({
  loading: false,
  eventRecord: {}
});

const getters = {
  loading: (state) => state.loading,
  eventRecord: state => state.eventRecord
};

const mutations = {
  FETCH_START(state) {
    state.loading = true;
  },
  FETCH_SUCCESS(state, payload) {
    state.eventRecord = payload
    state.loading = false;
  },
  FETCH_ERROR(state) {
    state.loading = false;
  },
};

const actions = {
  async getEvent({ commit }) {
    try {
      // debugger
      commit("FETCH_START");
      const result = await EventService.getEvent();
      console.log("resutl", result);
      commit("FETCH_SUCCESS", event);
      return true
    } catch (error) {
      console.error(error);
      Message.error(error.message)
      commit("FETCH_ERROR");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
