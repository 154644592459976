<template>
  <header class="main-header">
    <div class="main-header__inner">
      <div class="container">
        <div class="main-header__top">
          <div class="main-header__logo">
            <router-link to="/"><img src="@/assets/images/Aracia-Logo-white-01.png" alt="" />
            </router-link>
          </div>
          <div class="show-flag">
            <lang-toggle></lang-toggle>
          </div>
          <div class="main-header__contact-info">
            <ul class="main-header__contact-list list-unstyled" :class="isRTL ? 'listAr' : ''">
              <li>
                <div class="main-header__contact-icon">
                  <font-awesome-icon icon="fa-solid fa-comments" />
                </div>
                <div class="main-header__contact-text">
                  <p>
                    <app-i18n code="header.call" />
                  </p>
                  <a dir="ltr" class="nobreak" href="tel:530-435-5054">+1 (530) 435-5054</a>
                </div>
              </li>
              <li>
                <div class="main-header__contact-icon">
                  <font-awesome-icon icon="fa-solid fa-envelope" />
                </div>
                <div class="main-header__contact-text">
                  <p>
                    <app-i18n code="header.sendMail" />
                  </p>
                  <a href="mailto:info@aracia.org">info@aracia.org</a>
                </div>
              </li>
              <li>
                <div class="main-header__contact-icon">
                  <font-awesome-icon icon="fa-solid fa-map-location-dot" />
                </div>
                <div class="main-header__contact-text">
                  <p>
                    <app-i18n code="header.visit" />
                  </p>
                  <a href="https://maps.app.goo.gl/oN89osHti7qWLyw68?g_st=ic" target="_blank">
                    <h5 dir="ltr">
                      12848 Queensbury Ln #208, <br> Houston, TX 77024
                    </h5>
                  </a>
                </div>
              </li>
            </ul>
            <div class="main-header__btn">
              <a href="tel:+1(832) 228-2841" class="main-header__donate-btn">
                <font-awesome-icon icon="fa-solid fa-heart" />
                <app-i18n code="header.action" />
              </a>
              <lang-toggle></lang-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  created() {
    document.title = this.$t("app.title");
  },
  watch: {
    isRTL() {
      document.title = this.$t("app.title");
    },
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == "ar";
    },
  },
};
</script>
<style lang="scss">
.main-header {
  position: relative;
  // top: 0;
  // left: 0;
  // right: 0;
  z-index: 99;

  &__inner {
    position: relative;
    display: block;
    background-color: #294bac;

    .container {
      padding: 0 15px;

      .main-header__top {
        position: relative;
        display: flex;
        justify-content: space-around;
        padding: 36px 0;
        align-items: center;
        gap: 40px;

        @media (max-width: 1200px) {
          flex-direction: column;
          gap: 20px;
        }

        @media (max-width: 770px) {
          gap: 10px;
          padding: 12px 0;
        }

        .main-header__logo {
          // position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 120px;
          // padding: 25px 0 63px;

          img {
            // width: 120px;
            height: 65px;
          }
        }

        .show-flag {
          display: none;

          @media (max-width: 770px) {
            display: block;
          }
        }

        .main-header__contact-info {
          // position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 905px;
          width: 100%;
          gap: 2px;

          @media (max-width: 990px) {
            flex-direction: column;
          }

          @media (max-width: 770px) {
            display: none;
          }

          .main-header__contact-list {
            // position: relative;
            display: flex;
            margin: 0;
            // padding: 35px 0 58px;
            gap: 30px;

            @media (max-width: 990px) {
              width: 112%;
            }

            li {
              display: inline-flex;
              position: relative;
              align-items: center;

              // padding-left: 35px;
              // flex-direction: row-reverse;
              gap: 15px;

              &:before {
                content: "";
                position: absolute;
                top: -9px;
                right: -16px;
                bottom: 7px;
                background: rgba(255, 255, 255, 0.09);
                width: 1px;
              }

              &:last-child::before {
                content: none;
              }

              .main-header__contact-icon {
                // position: absolute;
                // top: 2px;
                // left: 0;

                svg {
                  font-size: 35px;

                  path {
                    color: #ff8c40;
                  }
                }
              }

              .nobreak {
                white-space: nowrap;
              }

              .main-header__contact-text {
                // margin-left: 15px;

                p {
                  font-size: 14px;
                  color: #afb2c0;
                  margin: 0;
                  line-height: 14px;
                }

                a,
                h5 {
                  text-decoration: none;
                  font-size: 15px;
                  color: #fff;
                  font-weight: 800;
                  transition: all 0.5s ease;

                  &:hover {
                    color: #ff8c40;
                  }
                }
              }
            }
          }

          .main-header__btn {
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            // padding-top: 55px;
            // padding-bottom: 39px;

            .main-header__donate-btn {
              text-decoration: none;
              font-size: 14px;
              color: #fff;
              font-weight: 900;
              background: #ff8c40;
              display: inline-flex;
              align-items: center;
              gap: 5px;
              padding: 7px 15px;
              transition: all 0.5s ease;
              border-radius: 6px;

              svg {
                path {
                  fill: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
