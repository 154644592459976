<template>
    <div class="modal-overlay" @click="$emit('close-modal')">
        <div class="modal-container" @click.stop v-if="!indep" @scroll="scrolled">
            <slot></slot>
            <!-- <div class="close">
                <font-awesome-icon icon="fa-solid fa-x" />
            </div> -->
            <div class="arrow-scroll" v-if="showArrow">&darr;</div>
        </div>
        <slot name="indep-modal"></slot>
    </div>
</template>
  
<script>
export default {
    props: {
        indep: {
            type: Boolean,
            default: false
        },
        scroll: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showArrow: this.scroll
        }
    },
    methods: {
        scrolled() {
            this.showArrow = false
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000da;
    z-index: 999999;
}

.modal-container {
    /* text-align: center; */
    background-color: white;
    /* min-height: 500px; */
    width: 70vw;
    /* margin-top: 10%; */
    padding: 60px 0;
    border-radius: 20px;
    max-height: 60vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0px !important;
        // color: $Pelorous !important;
        // background: $Pelorous;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #ff8c40 !important;
        // outline: 5px solid greenyellow !important;
    }
}

.close {
    margin: 10% 0 0 16px;
    cursor: pointer;
    position: absolute;
    top: 14%;
    right: 17%;
}

.check {
    width: 150px;
}

h6 {
    font-weight: 500;
    font-size: 28px;
    margin: 20px 0;
}

p {
    font-size: 16px;
    margin: 20px 0;
}

button {
    background-color: #ac003e;
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    border-radius: 16px;
    margin-top: 50px;
}

.bounce-enter-active {
    animation: bounce-in .5s ease-out both;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse ease-in both;
}

.fade-enter-active {
    animation: fadein .5s ease-out both;
}

.fade-leave-active {
    animation: fadein .5s reverse ease-in both;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.10);
    }

    100% {
        transform: scale(1);
    }
}

.arrow-scroll {
    position: fixed;
    top: 75%;
    left: 50%;
    font-size: 30px;
    color: #ff8c40;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: upDown;
    animation-duration: 1s;
}

@keyframes upDown {
    from {
        transform: translateY(-20px);
    }

    to {
        transform: translateY(0px);
    }
}
</style>
<!-- <style scoped>
</style> -->