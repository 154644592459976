<template>
    <section class="counters-one about-page-counter" ref="counterContainer">
        <div class="container">
            <ul class="counters-one__box list-unstyled">
                <li class="counter-one__single" v-for="(counter, i) in counters" :key="i">
                    <h3>
                        <span di="ltr">
                            <!-- <app-i18n :code="`counter.${counter.code}.number`" /> -->
                            <span> {{ counter.startVal }}</span>
                            <span v-if="counter.code == 'visitors'">+</span>
                        </span>
                    </h3>
                    <!-- <span class="counter-one__letter">M</span> -->
                    <p class="counter-one__text">
                        <app-i18n :code="`counter.${counter.code}.title`" />
                    </p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        const counterContainer = this.$refs.counterContainer
        this.counters.forEach(counter => {
            this.countUp(counterContainer, counter)
        })
    },
    data: () => ({
        counters: [
            { code: 'visitors', endVal: 4000, startVal: 0, speed: 1 },
            { code: 'connected', endVal: 67, startVal: 0, speed: 20 },
            { code: 'country', endVal: 22, startVal: 0, speed: 40 },
            { code: 'needed', endVal: 12, startVal: 0, speed: 40 },
        ]
    }),
    methods: {
        countUp(trigger, counter) {
            const count = () => {
                if (counter.startVal <= counter.endVal) {
                    if (counter.code === 'visitors') {
                        counter.startVal += 10
                    } else {
                        ++counter.startVal
                    }
                }
            }
            const observer = new IntersectionObserver(el => {
                if (el[0].isIntersecting) {
                    const interval = setInterval(() => {
                        counter.startVal < counter.endVal ? count() : clearInterval(interval)
                    }, counter.speed)
                }
            }, { threshold: [0] })
            observer.observe(trigger)
        }
    }
}
</script>

<style lang="scss">
.about-page-counter {
    margin-top: -50px;
    position: relative;
    display: block;
    z-index: 5;
}

.counters-one__box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 0 60px 0 rgb(0 0 0 / 7%);
    padding: 47px 0 51px;
}

.counter-one__single {
    position: relative;
    display: inline-block;
    max-width: 284px;
    width: 100%;
    text-align: center;
}

.counter-one__letter,
.counter-one__single h3 {
    display: inline-block;
    color: #FF8C40;
    font-size: 50px;
    font-weight: 900;
    vertical-align: middle;
}

.counter-one__text {
    font-weight: 800;
    margin: 0;
}
</style>