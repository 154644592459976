import { createRouter, createWebHistory } from "vue-router";
import Landing from "@/views/landing-page.vue";
import i18n from "@/i18n";
const octEvent = () => import("@/views/octoberEvent/octoberEvent.vue");
const RegisterForm = () => import("@/components/form/multistep-form.vue");
const EmailVerificationPage = () =>
  import("@/components/form/email-verification-page.vue");
const ErrorPage = () => import("@/views/error-page/error-page.vue");

const routes = [
  {
    path: "/",
    redirect: i18n.locale,
  },
  {
    path: "/:locale",
    children: [
      {
        path: "",
        name: "homePage",
        component: Landing,
      },
      {
        path: "october-event",
        name: "octoberEvent",
        component: octEvent,
      },
      {
        path: "join-us",
        name: "RegisterForm",
        component: RegisterForm,
      },
      {
        path: "finishVerification",
        name: "EmailVerificationPage",
        component: EmailVerificationPage,
      },
      {
        path: ":notFound(.*)*",
        component: ErrorPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // debugger
  let { locale } = to.params
  // check for language and redirect to the same path with default language
  if (!locale || (locale !== 'ar' && locale !== 'en')){
    locale = localStorage.getItem('language') || 'ar'
    return next({ path: `/${locale}${to.path}` });
  }

  if(locale !== 'ar' && locale !== 'en') locale = 'ar'
  i18n.locale = locale
  localStorage.setItem("language", locale)

  // i18n.locale = localStorage.getItem('language') || 'ar'
  return next()
})

export default router;
