<template>
    <div class="ticker-wrap" @click="$router.push('/october-event')">
        <div class="ticker">
            <div class="ticker__item" v-for="(item, i) in items" :key="i">
                <span  v-if="item.code" :style="{color: i == 4 || i == 6 ? '#294BAC' : '#f18b42'}"><app-i18n :code="`ticker.${item.code}`" /></span>
                <span v-else>{{item.text}}</span>
                <font-awesome-icon v-if="item.last" icon="fa-solid fa-heart" class="bumb"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        document.title = this.$t('app.title')
    },
    watch: {
        isRTL() {
            document.title = this.$t('app.title')
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar'
        }
    },
    data: () => ({
        items: [
            { code: 'event.1' },
            { text: '-' },
            { code: 'event.2' },
            { text: '-' },
            { code: 'event.3' },
            { text: '-' },
            { code: 'event.4', last: true }
        ]
    })
}
</script>


<style lang="scss">
$duration: 40s;

@keyframes ticker {
    0% {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    100% {
        transform: translate3d(85%, 0, 0);
    }
}

.ticker-wrap {
    position: relative;
    // top: 0;
    width: 100%;
    overflow: hidden;
    height: 3rem;
    background-color: rgba(#000, 0.9);
    // background-color: #f18b42;
    background-color: #fff;
    padding-left: -100%;
    box-sizing: content-box;
    z-index: 999;
    cursor: pointer;

    .ticker {
        display: inline-flex;
        height: 3rem;
        width: 100vw;
        line-height: 4rem;
        white-space: nowrap;
        padding-right: -100%;
        box-sizing: content-box;

        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: ticker;
        animation-duration: $duration;

        @media (max-width: 900px) {
            animation-duration: 10s;
        }
        // animation-direction: reverse;

        &__item {
            display: inline-flex;
            align-items: center;
            gap: 1rem;
            padding: 0 .4rem;
            font-size: 1.5rem;
            line-height: 1.5;
            // color: white;
            color: #f18b42;
        }
    }
}

#heart {
    position: relative;
    width: 100px;
    height: 90px;
    animation: heartbeat 1s infinite;
    margin:0 auto;
    z-index: 999;
}
#heart:before,
#heart:after {
    position: absolute;
    content: "";
    left: 50px;
    top: 0;
    width: 50px;
    height: 80px;
    background: #fff;
    -moz-border-radius: 50px 50px 0 0;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
       -moz-transform-origin: 0 100%;
        -ms-transform-origin: 0 100%;
         -o-transform-origin: 0 100%;
            transform-origin: 0 100%;
}
#heart:after {
    left: 0;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
       -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
         -o-transform-origin: 100% 100%;
            transform-origin :100% 100%;
}

.bumb {
    animation: heartbeat 1s infinite;
    color: red;
}



@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}
</style>