import * as firebase from "firebase/app";

export class AuthToken {
  // static async get() {
  //   if (
  //     firebase.apps.length &&
  //     firebase.auth().currentUser
  //   ) {
  //     return await firebase
  //       .auth()
  //       .currentUser.getIdToken(true);
  //   }

  //   return null;
  // }
  static async get() {
    console.log("Firebase Apps Count =", firebase.apps.length);
    // console.log('Current User =', firebase.auth().currentUser);

    let token = null;
    if (firebase.apps.length && firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true);
    }
    console.log("ID Token =", token);
    return token;
  }
}
