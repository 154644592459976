<template>
    <section class="about">
        <div class="container">
            <div class="row reverse-order">
                <div class="col-xl-6 col-lg-6">
                    <div class="about__left">
                        <div class="about__img-box" :class="isRTL ? 'about__img-box-ar' : ''"><img
                            v-lazy="{ src: require('@/assets/images/about.jpg'), loading: require('@/assets/images/about-lazy.jpg')}" >
                            <div class="about__img-box-2" :class="isRTL ? 'img-rt' : ''"><img
                                v-lazy="{ src: require('@/assets/images/about2.jpg'), loading: require('@/assets/images/about2-lazy.jpg')}"></div>
                            <h2 class="about__bottom-text" v-if="!isRTL">
                                <app-i18n code="about.titleImg" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="about__right">
                        <div class="section-title text-left"><span class="section-title__tagline">&nbsp;
                                <app-i18n code="about.sectionTitle" />
                            </span>
                            <h2 class="section-title__title">
                                <app-i18n code="about.title" />
                            </h2>
                        </div>
                        <p class="about__right-text">
                            <app-i18n code="about.about1" />
                        </p>
                        <ul class="about__right-list" :class="isRTL ? 'pe-3' : 'ps-3'">
                            <li>
                                <font-awesome-icon icon="fa-solid fa-minus" /><span>
                                    <app-i18n code="about.list1" />
                                </span>
                            </li>
                            <li>
                                <font-awesome-icon icon="fa-solid fa-minus" /><span>
                                    <app-i18n code="about.list2" />
                                </span>
                            </li>
                            <li>
                                <font-awesome-icon icon="fa-solid fa-minus" /><span>
                                    <app-i18n code="about.list3" />
                                </span>
                            </li>
                            <li>
                                <font-awesome-icon icon="fa-solid fa-minus" /><span>
                                    <app-i18n code="about.list4" />
                                </span>
                            </li>
                            <li>
                                <font-awesome-icon icon="fa-solid fa-minus" /><span>
                                    <app-i18n code="about.list5" />
                                </span>
                            </li>
                        </ul>
                        <p class="about__right-text">
                            <app-i18n code="about.about2" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    created() {
        document.title = this.$t("app.title");
    },
    watch: {
        isRTL() {
            document.title = this.$t("app.title");
        }
    },
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar'
        }
    },
}
</script>

<style lang="scss">
.about {
    position: relative;
    display: block;
    padding: 100px 0px 185px;

    .row {
        --bs-gutter-x: 110px;
    }

    .container {
        padding-left: 15px;
        padding-right: 15px;

        .reverse-order {
            @media (max-width: 992px) {
                flex-direction: column-reverse;
            }
        }

        .row {
            @media (max-width: 992px) {
                gap: 150px;
            }
        }

        .about__left {
            position: relative;
            display: block;

            .about__img-box {
                position: relative;
                display: block;
                
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -60px;
                    height: 330px;
                    width: 30px;
                    background: #FF8C40;

                    @media (max-width: 640px) {
                        content: none;
                    }
                }
                
                &-ar::before {
                    right: -60px;
                    left: auto;
                }
                
                img {
                    height: 700px;
                    width: 100%;
                    object-fit: cover;
                }
            }

            .about__img-box-2 {
                position: absolute;
                bottom: -30px;
                left: -60px;
                // height: 100%;

                &.img-rt {
                    right: -60px;
                }

                @media (max-width: 767px) {
                    display: none;
                }

                img {
                    object-fit: cover;
                    width: 320px;
                    height: 350px;
                }
            }

            .about__bottom-text {
                font-size: 50px;
                color: #FF8C40;
                font-family: "reeyregular" !important;
                position: absolute;
                bottom: -55px;
                left: 60px;
                line-height: 60px;

                @media (max-width: 767px) {
                    font-size: 40px;
                    bottom: 45px;
                    left: 0;
                    line-height: 50px;
                    text-align: center;
                    right: 0;
                }
            }

            .about__heart-icon {
                position: absolute;
                bottom: -88px;
                left: 345px;

                img {
                    width: 100%;
                }
            }
        }

        .about__right {
            .section-title {
                margin-bottom: 38px;
                margin-top: -13px;

                &__tagline {
                    display: inline-flex;
                    color: #FF8C40;
                    font-size: 18px;
                    font-weight: 900;
                    align-items: center;
                    line-height: 30px;

                    &:before {
                        content: "";
                        background-color: currentColor;
                        width: 44px;
                        height: 2px;
                        margin-right: 10px;
                    }
                }

                &__title {
                    margin: 0;
                    font-weight: 900;
                    color: #294BAC;
                    font-size: 50px;
                    line-height: 62px;
                }
            }

            &-text {
                margin: 0;
                line-height: 1.6;
            }

            &-list {
                list-style: none;
                padding: 0;
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                gap: 24px;

                li {
                    display: flex;
                    gap: 10px;
                    line-height: 1.1 !important;

                    span {
                        font-size: 18px;
                        line-height: 1.6;
                    }

                    svg {
                        font-size: 24px;

                        path {
                            color: #FF8C40;
                        }
                    }
                }
            }

            .about__our-mission-and-story {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 38px;

                .about__mission-and-story-single {
                    h3 {
                        margin: 0;
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 30px;
                        margin-bottom: 15px;
                        color: #294BAC;
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        svg {
                            width: 20px;
                            fill: #FF8C40;
                        }
                    }
                }

                &-text {
                    margin: 0;
                    font-size: 16px;
                }
            }

            .about__progress {
                position: relative;
                display: block;
                width: 100%;
                padding-top: 33px;

                &-title {
                    font-size: 20px;
                    font-weight: 900;
                    line-height: 30px;
                    margin-bottom: 6px;
                    color: #294BAC;
                }

                .bar {
                    position: relative;
                    width: 100%;
                    height: 13px;
                    background: #eff5f4;
                    border-radius: 5px;
                    margin-bottom: 12px;

                    .bar-inner {
                        position: relative;
                        display: block;
                        width: 0;
                        height: 13px;
                        border-radius: 5px;
                        background: #FF8C40;
                        transition: all 1.5s ease;

                        .count-text {
                            position: absolute;
                            right: 0;
                            bottom: 20px;
                            color: #707876;
                            line-height: 26px;
                            font-size: 16px;
                            text-align: center;
                            font-weight: 700;
                            opacity: 1;
                            transition: all .5s ease;
                        }
                    }
                }
            }

            .about__btn {
                display: inline-block;
                vertical-align: middle;
                border: none;
                outline: none !important;
                background-color: #FF8C40;
                color: #fff;
                font-size: 16px;
                font-weight: 900;
                font-size: 18px;
                font-weight: 800;
                padding: 20px 48px;
                display: inline-flex;
                align-items: center;
                gap: 10px;
                margin-top: 54px;

                svg {
                    width: 18px;
                    fill: #fff;
                }
            }
        }


    }
}
</style>