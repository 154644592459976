<template>
    <div class="base-input">
        <div class="row">
            <div class="input-field col s12">
                <input :min="minVal" v-model="model" :value="modelValue" :type="type" required placeholder=" " :pattern="pattern" :minlength="minLength"
                    :id="label" width="full" widthnumber="12" lastwidth="lastWidth" class="">
                <label class="" :for="label">{{label}}</label>
                <span class="form__bar"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        label: String,
        type: String,
        pattern: {
            type: String,
            default: '(.*?)'
        },
        minLength: {
            type: String,
            default: ''
        },
        minVal: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            model: this.modelValue
        }
    },
    watch: {
        model(currentValue) {
            this.$emit('update:modelValue', currentValue)
        }
    }
}
</script>

<style lang="scss" scoped>
// variables
$base: green;

.base-input {
    padding: 0rem;

    .input-field {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 20%;
        margin: 1.4rem 0;
        // padding: 0;
    }

    .input-checkbox {
        position: relative;
        display: flex;
    }

    input {
        border: 0;
        width: 100%;
        height: 40px;
        font-size: 1rem;
        outline: none;

        &[type='checkbox'] {
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            outline: none;
            content: none;
            width: 20px;

            &:required:valid {
                &+label {
                    top: 0;
                    font-size: inherit;
                    color: inherit;
                }
            }

            +label {
                position: relative;
                top: 0;
                font-size: inherit;
            }

            &:before {
                content: '';
                width: 12px;
                height: 12px;
                border: 1px solid grey;
                display: block;
                border-radius: 2px;
            }

            &:checked {
                &:before {
                    content: '✔';
                }
            }
        }

        &:required:valid {
            outline: 0;

            &+label {
                top: -10px;
                font-size: 10px;
                color: $base;
            }

            &~.form__bar {
                &:after {
                    width: 100%;
                    margin: auto;
                    background: $base;
                }
            }
        }

        &:not(:placeholder-shown) {
            &:invalid {
                &+label {
                    top: -10px;
                    font-size: 10px;
                    color: red;
                }

                &~.form__bar {
                    &:after {
                        width: 100%;
                        margin: auto;
                        background: red;
                    }
                }
            }

            &:valid {
                &+label {
                    top: -10px;
                    font-size: 10px;
                    color: $base;
                }

                &~.form__bar {
                    &:after {
                        width: 100%;
                        margin: auto;
                        background: $base;
                    }
                }
            }
        }

        &:focus {
            outline: 0;

            &:required {
                &:invalid {
                    &+label {
                        top: -10px;
                        font-size: 10px;
                        color: red;
                    }

                    &~.form__bar {
                        &:after {
                            width: 100%;
                            margin: auto;
                            background: red;
                        }
                    }
                }
            }

            &+label {
                top: -10px;
                font-size: 10px;
                color: $base;
            }

            &~.form__bar {
                &:after {
                    width: 100%;
                    margin: auto;
                    background: $base;
                }
            }
        }
    }

    &:focus {
        &:valid {
            outline: 0;

            &+label {
                top: -10px;
                font-size: 10px;
                color: $base;
            }

            &~.form__bar {
                &:after {
                    width: 100%;
                    margin: auto;
                    background: $base;
                }
            }
        }
    }


    label {
        position: absolute;
        font-size: 100%;
        // right: 0;
        right: 14px;
        // left: 0;
        left: 14px;
        top: 20%;
        transition: all 200ms ease-in;
    }

    .form__bar {
        width: 96%;
        height: 1px;
        background: rgba(gray, 0.2);
        display: block;
        top: 2.5rem;
        position: absolute;

        &:after {
            content: '';
            transition: all 200ms ease-in;
            width: 0;
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            height: 1px;
            background: $base;
        }
    }

    button[type="submit"] {
        display: block;
        padding: 1rem;
        border-radius: 25px;
        min-width: 162px;
        background: #F92822;
        color: white;
    }
}
</style>