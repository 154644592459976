import i18n from "@/i18n";

const firebaseConfig = {
  apiKey: "AIzaSyDEfDWaD33jyoizzowY3rkY8KpLaDlXbDE",
  authDomain: "aracia-org.firebaseapp.com",
  projectId: "aracia-org",
  storageBucket: "aracia-org.appspot.com",
  messagingSenderId: "728466447303",
  appId: "1:728466447303:web:254d704c0a32cfbc9657e2",
  measurementId: "G-2BY3DJWC3N",
};

const backendUrl = 'https://europe-west1-aracia-org.cloudfunctions.net'
const redirectUrl = `https://aracia.org/${i18n.locale || 'ar'}/finishVerification`

export default {
  firebaseConfig,
  backendUrl,
  redirectUrl
};
