<template>
  <footer class="main-footer">
    <!-- <div class="main-footer-bg" :style="{backgroundImage: `url(${require('@/assets/images/footer-bg.jpg')})`}"> -->
    <div class="main-footer-bg"></div>
    <div class="container">
      <div class="main-footer__top">
        <div class="row more-width">
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="footer-widget__column footer-widget__about">
              <h3 class="footer-widget__title">
                <app-i18n code="footer.about.title" />
              </h3>
              <p class="footer-widget__text">
                <app-i18n code="footer.about.content" />
              </p>
              <a href="tel:+1(832) 228-2841" class="footer-widget__about-btn btn">
                <font-awesome-icon icon="fa-solid fa-heart" />
                <app-i18n code="footer.about.action" />
              </a>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6 more">
            <div class="footer-widget__column footer-widget__explore">
              <h3 class="footer-widget__title">
                <app-i18n code="footer.team.title" />
              </h3>
              <div class="footer-widget__lists">
                <ul class="footer-widget__explore-list list-unstyled">
                  <li>
                    <ul>
                      <li>
                        <app-i18n code="footer.team.list.1.dep" />
                      </li>
                      <li>
                        <app-i18n code="footer.team.list.1.name" />
                      </li>
                      <li dir="ltr">
                        <a href="tel:+1(832) 228-2841">
                          <app-i18n code="footer.team.list.1.number" />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:Naglaa@aracia.org">Naglaa@aracia.org</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <app-i18n code="footer.team.list.2.dep" />
                      </li>
                      <li>
                        <app-i18n code="footer.team.list.2.name" />
                      </li>
                      <li dir="ltr">
                        <a href="tel:+1(713) 679-0782">
                          <app-i18n code="footer.team.list.2.number" />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:Yasser@aracia.org">Yasser@aracia.org</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <app-i18n code="footer.team.list.3.dep" />
                      </li>
                      <li>
                        <app-i18n code="footer.team.list.3.name" />
                      </li>
                      <li dir="ltr">
                        <a href="tel:+1(832) 640-7602">
                          <app-i18n code="footer.team.list.3.number" />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:Hany@aracia.org">Hany@aracia.org</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <app-i18n code="footer.team.list.4.dep" />
                      </li>
                      <li>
                        <app-i18n code="footer.team.list.4.name" />
                      </li>
                      <li dir="ltr">
                        <a href="tel:+1(530) 609-8932">
                          <app-i18n code="footer.team.list.4.number" />
                        </a>
                      </li>
                      <li>
                        <a href="mailto:Shahe@aracia.org">Shahe@aracia.org</a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!-- <ul class="footer-widget__explore-list footer-widget__explore-list-two list-unstyled">
                                    <li><a href="javascript:;">
                                            <app-i18n code="footer.explore.list.6" />
                                        </a></li>
                                    <li><a href="javascript:;">
                                            <app-i18n code="footer.explore.list.7" />
                                        </a></li>
                                    <li><a href="javascript:;">
                                            <app-i18n code="footer.explore.list.8" />
                                        </a></li>
                                    <li><a href="javascript:;">
                                            <app-i18n code="footer.explore.list.9" />
                                        </a></li>
                                </ul> -->
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="footer-widget__column footer-widget__contact">
              <h3 class="footer-widget__title">
                <app-i18n code="footer.contact.title" />
              </h3>
              <ul class="list-unstyled footer-widget__contact-list">
                <li>
                  <div class="icon">
                    <font-awesome-icon icon="fa-solid fa-comments" />
                  </div>
                  <div class="text">
                    <p>
                      <span>
                        <app-i18n code="footer.contact.call" />
                      </span>
                      <a dir="ltr" href="tel:530-435-5054">+1 (530) 435-5054</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                  </div>
                  <div class="text">
                    <p>
                      <span>
                        <app-i18n code="footer.contact.sendMail" />
                      </span>
                      <a href="mailto:info@aracia.org">info@aracia.org</a>
                    </p>
                  </div>
                </li>
                <li>
                  <div class="icon">
                    <font-awesome-icon icon="fa-solid fa-map-location-dot" />
                  </div>
                  <div class="text">
                    <a href="https://maps.app.goo.gl/oN89osHti7qWLyw68?g_st=ic" target="_blank">
                      <p dir="ltr">
                        <span>
                          <app-i18n code="footer.contact.visit" />
                        </span>
                        12848 Queensbury Ln #208, Houston, TX 77024
                      </p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-xl-4 col-lg-6 col-md-6">
                        <div class="footer-widget__column footer-widget__newsletter">
                            <h3 class="footer-widget__title">Newsletter</h3>
                            <p class="footer-widget__newsletter-text">
                                Lorem ipsum dolor sit ame consect etur pisicing elit sed do.
                            </p>
                            <form class="footer-widget__newsletter-form"><input type="email" placeholder="Email address"
                                    name="email"> <button type="submit" class="footer-widget__newsletter-btn"><i
                                        class="fas fa-arrow-circle-right"></i>Send
                                </button></form>
                        </div>
                    </div> -->
        </div>
      </div>
      <div class="main-footer__bottom">
        <div class="row">
          <div class="col-xl-12">
            <div class="main-footer__bottom-inner">
              <div class="main-footer__bottom-logo-social">
                <div class="main-footer__bottom-logo">
                  <a href="javascript:;"><img src="@/assets/images/Aracia-Logo-01.png" alt="" /></a>
                </div>
                <div class="main-footer__bottom-social">
                  <a href="https://www.twitter.com/AraciaOfficial" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-twitter" />
                  </a>
                  <a href="https://www.facebook.com/AraciaOfficial" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-facebook-f" />
                  </a><a href="https://www.instagram.com/AraciaOfficial" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-instagram" />
                  </a>
                  <a href="https://www.linkedin.com/company/AraciaOfficial/" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                  </a>
                  <a href="https://www.pinterest.com/AraciaOfficial/" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-pinterest" />
                  </a>
                  <a href="https://www.tiktok.com/@AraciaOfficial" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-tiktok" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCPugdTeryIbELJsEbytBbvA" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-youtube" />
                  </a>
                  <a href="https://www.snapchat.com/@AraciaOfficial" target="_blank">
                    <font-awesome-icon icon="fa-brands fa-snapchat" />
                  </a>
                </div>
              </div>
              <div class="main-footer__bottom-copy-right">
                <p>
                  &copy;
                  <app-i18n code="footer.copy" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.main-footer {
  position: relative;
  padding: 92px 0 0;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: grayscale(0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;

    &::before {
      content: "";
      background-color: rgba(31, 34, 48, 0.95);
      background-color: rgba(#294bac, 0.95);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__top {
    position: relative;
    
    .more-width {
      .col-md-6.more {
        // width: 60%;
      }
    }
    .row {
      // --mdb-gutter-x: 5rem;


      @media (max-width: 1200px) {
        gap: 2rem;
      }
    }

    .footer-widget {
      &__title {
        font-size: 22px;
        color: #fff;
        line-height: 32px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      &__text {
        font-size: 16px;
        color: #b8bbc9;
        margin: 0;
        line-height: 34px;
      }

      &__about-btn {
        font-size: 14px;
        font-weight: 900;
        background: #ff8c40;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        color: #fff;
        padding: 7px 16px;
        transition: all 0.5s ease;
        margin-top: 18px;

        svg {
          path {
            color: #fff;
          }
        }
      }

      &__lists {
        display: flex;
        justify-content: start;
        gap: 55px;
      }

      &__explore-list {
        position: relative;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        // flex-direction: column;
        gap: 2rem;

        @media (max-width: 600px) {
          grid-template-columns: 1fr;
        }

        li {
          ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            li {
              &:first-child {
                color: #fff;
              }

              color: #b8bbc9;
            }
          }

          a {
            font-size: 16px;
            color: #b8bbc9;
            font-weight: 700;
            transition: all 0.5s ease;

            &:hover {
              color: #ff8c40;
            }
          }
        }
      }

      &__contact-list {
        margin: 0;
        padding-top: 11px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 11px 0 0;

        li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgb(255 255 255 / 10%);
          padding-bottom: 15px;
          gap: 15px;

          .icon {
            svg {
              font-size: 35px;

              path {
                color: #ff8c40;
              }
            }
          }

          .text {
            p {
              font-size: 16px;
              color: #fff;
              font-weight: 800;
              margin: 0;
              line-height: 20px;

              span {
                font-size: 14px;
                color: #afb2c0;
                font-weight: 700;
                display: block;
                line-height: 20px;
              }

              a {
                font-size: 16px;
                color: #fff;
                font-weight: 800;
                transition: all 0.5s ease;
                line-height: 20px;

                &:hover {
                  color: #ff8c40;
                }
              }
            }
          }
        }
      }
    }
  }

  &__bottom {
    position: relative;
    display: block;
    background: #1b1e2b;
    z-index: 1;
    margin-top: 87px;

    &::before {
      position: absolute;
      top: 0;
      right: -10000000000px;
      left: 0;
      bottom: 0;
      // content: "";
      background: #1b1e2b;
      z-index: -1;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: -10000px;
      bottom: 0;
      background: #1b1e2b;
      z-index: -1;

      @media (max-width: 1000px) {
        // content: "";
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 1000px) {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
      }

      @media (max-width: 500px) {
        align-items: center;
      }
    }

    &-logo-social {
      display: flex;
      align-items: center;
      gap: 60px;

      @media (max-width: 500px) {
        flex-direction: column;
        gap: 20px;
      }
    }

    &-logo {
      // background-color: #FF8C40;
      background-color: #fff;
      padding: 43.5px 69.5px;

      @media (max-width: 600px) {
        padding: 43.5px 34px;
      }

      @media (max-width: 325px) {
        padding: 43.5px 0px;
      }

      img {
        // width: 120px;
        height: 55px;
      }
    }

    &-social {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      padding: 8px 14px;

      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 35px;
        height: 35px;
        background-color: #fff;
        color: #294bac;
        font-size: 13px;
        border-radius: 50%;
        transition: all 0.5s ease;

        &:hover {
          color: #fff;
          background: #ff8c40;
        }

        svg {
          width: 16px;
          fill: #294bac;
        }
      }
    }

    &-copy-right {
      margin-left: 14px;

      @media (max-width: 1400px) {
        margin: 0 auto;
      }


      p {
        font-size: 16px;
        color: #b8bbc9;
        margin: 0;
        text-align: center;
        padding: 0 15px;

        @media (max-width: 1200px) {
          padding: 0 15px 10px;
      }

        a {
          color: #b8bbc9;
          transition: all 0.5s ease;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
