import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";

import ErrorNotify from "@/components/toastify/error-notify.vue";
import SuccessNotify from "@/components/toastify/success-notify.vue";
import JoinedNotify from "@/components/toastify/joined-notify.vue";

import "bootstrap";
import "mdb-vue-ui-kit/css/mdb.min.css";

import "vue-select/dist/vue-select.css";
import i18n from "./i18n";
import I18n from "./components/i18n/i18n.vue";
import LanguageSwitch from "./components/language-switcher/Main.vue";
import VueTelInput from "vue-tel-input";
import vSelect from "vue-select";
import "vue-tel-input/dist/vue-tel-input.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import baseInput from "@/components/base-input/base-input.vue";

/* add icons to the library */
library.add(fas, far, fab);

import VueLazyLoad from "vue3-lazyload";

import "@/assets/fonts/reeyregular/stylesheet.css";
const app = createApp(App).use(store).use(router).use(i18n);

app.use(VueLazyLoad);
app.component("ErrorNotify", ErrorNotify);
app.component("SuccessNotify", SuccessNotify);
app.component("JoinedNotify", JoinedNotify);
app.component("app-i18n", I18n);
app.component("lang-toggle", LanguageSwitch);
app.component("v-select", vSelect);
app.component("base-input", baseInput);
app.component("font-awesome-icon", FontAwesomeIcon);

app.use(Vue3Lottie);
app.use(VueTelInput);

app.mount("#app");
